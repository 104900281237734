import { createSlice } from "@reduxjs/toolkit";
import { IOrder } from "../../models";
import {fetchFilterOrders, fetchOrders} from "../actions/ordersActions";

interface IOrderStore {
    next: string | null
    previous: null | string
    count: number
    total_pages: number
    results: IOrder[]
}

const initialState: IOrderStore = {
    next: null,
    previous: null,
    count: 0,
    total_pages: 0,
    results: []
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchOrders.fulfilled.type]: (state, action) => {
        // state.next = action.payload.next
        // state.previous = action.payload.previous
        // state.count = action.payload.count
        // state.total_pages = action.payload.total_pages
        // state.results = action.payload.results || []
        state.results = action.payload
    },
    [fetchFilterOrders.fulfilled.type]: (state, action) => {
        // state.next = action.payload.next
        // state.previous = action.payload.previous
        // state.count = action.payload.count
        // state.total_pages = action.payload.total_pages
        // state.results = action.payload.results || []
        state.results = action.payload
    }
  },
});

export default ordersSlice.reducer