import React, { useState } from 'react'
import {
    Box,
    Button,
    CardMedia,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Stack,
    Tab,
    Typography,
    ButtonGroup
} from "@mui/material";
import { useToggle } from "../../hooks/use-toggle";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useTranslation } from "react-i18next";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { fDashedDate, fDate } from "../../utils/utils";
import noAvatar from '../../assets/no_img.jpg'
import noImg from "../../assets/no-car.webp";
import { fetchDownloadOrder } from "../../redux/actions/ordersActions";
import SaveAlt from '@mui/icons-material/SaveAlt'
import OrderCancel from './OrderCancel';
import OrderProlong from "./OrderProlong";
import i18next from 'i18next'
import { fCurrency } from "../../utils/utils";


interface IProps {
    open: boolean
    onClose: () => void
    orderId: number
    setOrderId: () => void
}
const server_url = 'https://4set.uz'

const OrderModal = (props: IProps) => {

    const { open, onClose, orderId, setOrderId } = props

    const dispatch = useAppDispatch()
    const [tab, setTab] = useState('1')

    const { t } = useTranslation()
    const { results: orders } = useAppSelector((state) => state.ordersReducer);
    const foundOrder = orders.find(order => order.order_id === orderId) || null

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTab(newValue)
    }

    const edit = useToggle(false)
    const prolong = useToggle(false)

    const close = () => {
        onClose()
        edit.off()
        prolong.off()
        setOrderId()
    }

    const downloadOrderDoc = async () => {
        if (foundOrder?.order_id) {
            const res = await dispatch(fetchDownloadOrder(foundOrder.order_id, 'ru'))
            if (res?.status === 200) {
                const link = document.createElement("a")
                link.href = `${server_url}/${res?.data.document}`
                link.download = `${res?.data.filename}`
                link.click()
            }
        }
    }
     const downloadOrderDocUz = async () => {
        if (foundOrder?.order_id) {
            const res = await dispatch(fetchDownloadOrder(foundOrder.order_id, 'uz'))
            if (res?.status === 200) {
                const link = document.createElement("a")
                link.href = `${server_url}/${res?.data.document}`
                link.download = `${res?.data.filename}`
                link.click()
            }
        }
    }

    if (!foundOrder) return null

    return (
        <Dialog open={open} onClose={() => close()} maxWidth="md">
            <DialogTitle>
                {t('order')} {`${foundOrder?.brand} ${foundOrder?.model}`}
            </DialogTitle>
            <DialogContent>
                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTabChange} aria-label="Order tabs">
                            <Tab label={t('car')} value="1" />
                            <Tab label={t('client')} value="2" />
                            {/*<Tab label="Item Three" value="3" />*/}
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Stack display="flex" direction="row">
                            <Box sx={{ p: 2 }} className={"flex col jcc"}>
                                <Typography variant={"h5"} component={"div"}>
                                    {foundOrder.brand} {foundOrder.model}
                                </Typography>
                                <Typography variant={"h6"} component={"div"}>
                                    {foundOrder.car_num}
                                </Typography>
                            </Box>
                            <CardMedia
                                component={"img"}
                                sx={{ height: 200, width: 400 }}
                                image={!!foundOrder.car_photo ? `${server_url}/media/${foundOrder.car_photo}` : noImg}
                            />
                        </Stack>
                    </TabPanel>
                    <TabPanel value="2">
                        <Stack display="flex" direction="row">
                            <Box sx={{ p: 2 }} className={"flex col jcc"}>
                                <Typography variant={"h5"} component={"div"}>
                                    {foundOrder.firstname} {foundOrder.lastname}
                                </Typography>
                                <Typography variant={"h6"} component={"div"}>
                                    {foundOrder.passport}
                                </Typography>
                                <Typography variant={"h6"} component={"div"}>
                                    {foundOrder.client_phone}
                                </Typography>
                            </Box>
                            <CardMedia
                                component={"img"}
                                sx={{ height: 200, width: 400 }}
                                image={!!foundOrder.client_photo[0] ? `${server_url}${foundOrder.client_photo[0].file}` : noAvatar}
                            />
                        </Stack>
                    </TabPanel>
                    {/*<TabPanel value="3">Item Three</TabPanel>*/}
                </TabContext>
                <Divider sx={{ my: 2 }} />
                <Box className="flex aic jcsb">
                    <Typography variant="body1">
                        {t('startingDate')}: {fDate(foundOrder.start, i18next.language)} <br />
                        {t('endingDate')}: {fDate(foundOrder.is_extended ? foundOrder.extended_to : foundOrder.end, i18next.language)}
                    </Typography>
                    <Typography variant="body1">
                        {t('startingTime')}: {foundOrder.start_time} <br />
                        {t('endingTime')}: {foundOrder.end_time}
                    </Typography>
                </Box>
                <Divider sx={{ my: 2 }} />

                <Box>
                     <Typography>
                        {t('pledge')}: {foundOrder.pledge}
                    </Typography>
                    <Typography>
                        {t('order_total_cost')}: {fCurrency(foundOrder.total_cost.toString()) + ' сум'}
                    </Typography>
                    <Typography variant="body1">
                        {t('order_notes')}: {foundOrder.notes ? foundOrder.notes : ''}
                    </Typography>
                </Box>
                <Stack>
                    <ButtonGroup variant="outlined" sx={{ mt: 1 }}>
                        {
                            foundOrder.status === "in progress" && (
                                <>
                                    <OrderProlong orderId={foundOrder.order_id} {...foundOrder} />
                                    <OrderCancel orderId={foundOrder.order_id}
                                                 total_cost={foundOrder.total_cost}
                                                 notes={foundOrder.notes} />
                                </>
                            )
                        }
                        <Button className={"flex aic jcc"} color="primary" onClick={downloadOrderDoc} variant="outlined">
                            <SaveAlt /> &nbsp; {t('download_ru')}
                        </Button>
                        <Button className={"flex aic jcc"} color="primary" onClick={downloadOrderDocUz} variant="outlined">
                            <SaveAlt /> &nbsp; {t('download_uz')}
                        </Button>
                    </ButtonGroup>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export default React.memo(OrderModal)