import React, {useState} from 'react'
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent, TextField
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../hooks/redux'
import { useToggle } from '../../hooks/use-toggle'
import {useForm} from 'react-hook-form'
import {IOrder, IOrderExtend, orderProlongModel} from '../../models'
import DatePicker from "../datepicker/DatePicker";
import {copyObject, fTime} from "../../utils/utils";
import {useFormState} from "../../hooks/use-form";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import {MUI_INPUTS_SIZE} from "../../const";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import ruLocale from "date-fns/locale/ru";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {fetchExtendOrder} from "../../redux/actions/ordersActions";
import {appSlice} from "../../redux/reducers/appReducer";
import moment from "moment";

interface IProps extends IOrder {
    orderId: number
}

const OrderProlong = (props: IProps) => {

    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const modal = useToggle(false)
    const [time, setTime] = useState<any>(null)

    const {control, handleSubmit, formState: {errors, submitCount}} = useForm<IOrderExtend>({
        defaultValues: copyObject(orderProlongModel),
    })

    const service = useFormState(errors, submitCount)

    const extendOrder = async (data: IOrderExtend) => {
        data.order_id = props.orderId
        data.to_time = fTime(time)

        const fd = new FormData()

        for (const [key, value] of Object.entries(data)) {
            fd.append(key, value)
        }

        const res = await dispatch(fetchExtendOrder(fd))
        if (res?.status === 201) {
            dispatch(appSlice.actions.toggleSystemAlert({
                severity: "success",
                text: t('orderExtendSuccess'),
                show: true,
                autoHide: true
            }))
            modal.off()
        }
    }

    return (
        <>
            <Button onClick={() => modal.on()}>
                {t('prolong')}
            </Button>
            <Dialog open={modal.state} onClose={() => modal.toggle()}>
                <DialogTitle>
                    {t('newOrderParams')}
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit(extendOrder)}>
                        <DatePicker
                            control={control}
                            name="to_date"
                            label={t('endDate')}
                            errors={errors}
                            minDate={props.is_extended ? moment(props.extended_to).add(1, 'day').toDate() : moment(props.end).add(1, 'day').toDate()}
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
                            <TimePicker
                                value={time}
                                onChange={(newValue) => {
                                    setTime(newValue);
                                }}
                                renderInput={(params) => <TextField size={MUI_INPUTS_SIZE} label={t('endTime')} {...params} />}
                            />
                        </LocalizationProvider>
                        <br/>
                        <Button sx={{mt: 2}} type={"submit"}>
                            {t('send')}
                        </Button>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default React.memo(OrderProlong)