import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import { LocalizationProvider } from '@mui/x-date-pickers'

import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Autocomplete, Button, Grid, TextField} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";
import {useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import ruLocale from "date-fns/locale/ru";
import {MUI_INPUTS_SIZE} from "../../const";
import {fetchCarNums} from "../../redux/actions/carrepairAction";
import {useAppDispatch} from "../../hooks/redux";
import SaveAlt from "@mui/icons-material/SaveAlt";
import {fetchDownloadExcel} from "../../redux/actions/carrepairAction";
import { fDashedDate, fTime } from '../../utils/utils'


const server_url = 'https://4set.uz'


interface KeyValue {
    key: string;
    value: string;
    }

export default function DownloadAccordion() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [selectedCar, setSelectedCar] = useState<string | null>(null)
  const [CarNums, setCarNums] = useState([])

  const [startDay, setStartDay] = useState<Date | null>(null);
  const [endDay, setEndDay] = useState<Date | null>(null);

  const handleCarNumChange = (value: string | null) => {
        value ? setSelectedCar(value) : setSelectedCar(null)
    }

    const car_num_array = (car_nums: KeyValue[]) => {
        const cars: string[] = [];
        for (let i = 0; i < car_nums.length; i++) {
            cars.push(car_nums[i].value)
        }
        // @ts-ignore
        setCarNums(cars)
    }
     useEffect(() => {
           const fetchData = async () => {
               const data = await dispatch(fetchCarNums(''))

        setCarNums(data.payload.results);
         car_num_array(data.payload.results);

           }
        // setCarNums(data)
         fetchData()
     },[])

    const downloadExcel = async () => {

            const res = await dispatch(fetchDownloadExcel({
                car:selectedCar, start: fDashedDate(startDay), end: fDashedDate(endDay)
            }))
            if (res?.status === 200) {
                const link = document.createElement("a")
                link.href = `${server_url}/${res?.data.document}`
                link.download = `${res?.data.filename}`
                link.click()
            }
    }

  return (  <div style={{paddingBottom: '15px'}}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>{t('car_repair_download_title')}</Typography>
        </AccordionSummary>
        <AccordionDetails >
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
                    <form >
                         <Grid container spacing={3}>

                            <Grid item sm={6} md={3}>
                                <Typography variant="h6" component="h6">
                                    {t('startDate')}
                                </Typography>
                                <DatePicker
                                    value={startDay}
                                    onChange={(newValue) => {
                                        setStartDay(newValue);
                                    }}
                                    renderInput={(params) => <TextField size="small" sx={{ mt: 1 }} {...params} />}
                                />
                            </Grid>
                             <Grid item sm={6} md={3}>
                                <Typography variant="h6" component="h6">
                                    {t('endDate')}
                                </Typography>
                                <DatePicker
                                    value={endDay}
                                    onChange={(newValue) => {
                                        setEndDay(newValue);
                                    }}
                                    renderInput={(params) => <TextField size="small" sx={{ mt: 1 }} {...params} />}
                                />
                            </Grid>
                             <Grid item sm={6} md={3}>
                                  <Typography variant="h6" component="h6">
                                    {t('car_repair_car_num')}
                                </Typography>
                                  <Autocomplete
                                    options={CarNums || []}
                                    value={selectedCar}
                                    fullWidth
                                    sx={{ mt: 1, mx: 2, marginLeft:0 }}
                                    onChange={(e, data) => handleCarNumChange(data)}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextField {...params} size={MUI_INPUTS_SIZE} label={t('number')} />
                                    )}
                                />
                             </Grid>
                             <Grid item sm={6} md={3} sx={{marginTop: '40px'}} >
                                 <Button className={"flex aic jcc"} color="primary" sx={{padding: '6px'}}  onClick={downloadExcel} variant="outlined">
                                    <SaveAlt /> &nbsp; {t('car_repair_download_button')}
                                </Button>
                             </Grid>
                         </Grid>
                    </form>
            </LocalizationProvider>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}