import { createAsyncThunk } from "@reduxjs/toolkit";
import api from '../../api/clinets'
import {RootDispatch} from "../reducers/RootReducer";

const SERVER_ERROR = 'Ошибка на стороне сервера'

export const fetchClients = createAsyncThunk(
    'clients/fetchAll',
    async (payload: any, thunkAPI) => {
        try {
            const res = await api.getClients(payload)
            return res.data
        } catch (e) {
            return thunkAPI.rejectWithValue(SERVER_ERROR)
        }
    }
)

export const fetchClientsWithFilter = createAsyncThunk(
    'clients/fetchWithFilter',
    async (payload: any, thunkAPI) => {
        try {
            const res = await api.clientsFilter(payload)
            return res.data
        } catch (e) {
            return thunkAPI.rejectWithValue(SERVER_ERROR)
        }
    }
)

export const fetchEditClient = (data: FormData, clientId: number) => async (dispatch: RootDispatch) => {
    try {
        const res = await api.editClient(data, clientId)
        if (res.status === 200) {
            dispatch(fetchClients(''))
        }
        return res
    } catch (e) {
        console.log(e)
    }
}

export const fetchEditClientPhoto = (photoId: number, file: FormData) => async (dispatch: RootDispatch) => {
    try {   
        return await api.editClientPhoto(photoId, file);
    } catch (error) {
        console.log(error)
    }
}

export const fetchCreateClient = (data: FormData) => async (dispatch: RootDispatch) => {
    try {
        return await api.createClient(data)
    } catch (e) {
        console.log(e)
    }
}

export const fetchDeleteClient = (clientId: number) => async (dispatch: RootDispatch) => {
    try {
        return await api.deleteClient(clientId)
    } catch (e) {
        console.log(e)
    }
}

export const fetchBlockClient = (data: FormData) => async (dispatch: RootDispatch) => {
    try {
        return await api.blockClient(data)
    } catch (e) {
        console.log(e)
    }
}

export const fetchUnBlockClient = (id: number) => async (dispatch: RootDispatch) => {
    try {
        return await api.unblockClient(id)
    } catch (e) {
        console.log(e)
    }
}