import React, {useState, useRef, useMemo} from 'react'
import {
    Stack, Autocomplete, TextField, Button
} from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { fetchCarsWithFilter } from '../../redux/actions/carsActions'
import { MUI_INPUTS_SIZE } from '../../const'
import ManageSearch from "@mui/icons-material/ManageSearch";
import { useTranslation } from 'react-i18next'

interface IProps {
    padding?: boolean
}

const CarsFilter = ({padding}: IProps) => {

    const dispatch = useAppDispatch()

    const {modelbrand} = useAppSelector(state => state.carsReducer)
    const {t} = useTranslation()

    const brandRef = useRef<HTMLInputElement | null>(null)
    const modelRef = useRef<HTMLInputElement | null>(null)

    const [selectedBrand, setSelectedBrand] = useState<string | null>(null)
    const [selectedModel, setSelectedModel] = useState<string | null>(null)

    const brands = useMemo(() => {
        return modelbrand.map(i => Object.keys(i)).map(i => i[0])
    }, [modelbrand])

    const models = useMemo(() => {
        const found = selectedBrand ? modelbrand.find(i => `${Object.keys(i)}` === `${selectedBrand}`) || [] : []
        return found ? Object.values(found)[0] : []
    }, [selectedBrand, modelbrand])

    const handleBrandChange = (value: string | null) => {
        if (value) {
            if (value !== selectedBrand) {
                setSelectedModel(null)
            }
            setSelectedBrand(value)
        } else {
            setSelectedModel(null)
            setSelectedBrand(null)
        }
    }
    const handleModelChange = (value: string | null) => {
        value ? setSelectedModel(value) : setSelectedModel(null)
    }

    const searchByFilter = async () => {
        dispatch(fetchCarsWithFilter({model: selectedModel, brand: selectedBrand}))
    }

    return (
        <Stack display={"flex"} alignItems="center" justifyContent="center" direction={{ sm: 'column', md: 'row' }} sx={{ mt: 2, px: padding ? 4 : 0 }}>
            <Autocomplete
                options={brands || []}
                value={selectedBrand}
                fullWidth
                sx={{ mt: 1, mx: 2, marginLeft:0 }}
                onChange={(e, data) => handleBrandChange(data)}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                    <TextField {...params} inputRef={brandRef} size={MUI_INPUTS_SIZE} label={t('brand')} />
                )}
            />
            <Autocomplete
                options={models || []}
                value={selectedModel}
                fullWidth
                sx={{ mt: 1, mx: 2 }}
                onChange={(e, data) => handleModelChange(data)}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                    <TextField {...params} inputRef={modelRef} size={MUI_INPUTS_SIZE} label={t('model')} />
                )}
            />
            <Button sx={{ mt: 1, mx: 2, width: '15rem' }} color="primary" onClick={searchByFilter} variant={"outlined"}>
                <ManageSearch /> {t('search')}
            </Button>
        </Stack>
    )
}

export default React.memo(CarsFilter)