import { createSlice } from "@reduxjs/toolkit"
import { IBlackList } from "../../models"
import { fetchBlackListClients, fetchBlackListClientsWithFilter } from "../actions/blacklistActions"

interface IBlackListClientStore {
    count: number
    next: string | null
    previous: string | null
    results: IBlackList[]
    total_pages: number
}

const initialState: IBlackListClientStore = {
    count: 0,
    next: null,
    previous: null,
    results: [],
    total_pages: 0
}

export const blacklistclientsSlice = createSlice({
    name: "blacklist_clients",
    reducers: {},
    initialState,
    extraReducers: {
        [fetchBlackListClients.fulfilled.type]: (state, action) => {
            state.count = action.payload.count
            state.next = action.payload.next
            state.previous = action.payload.previous
            state.total_pages = action.payload.total_pages
            state.results = action.payload.results
        },
        [fetchBlackListClientsWithFilter.fulfilled.type]: (state, action) => {
            state.results = action.payload.results
        }
    }
})

export default blacklistclientsSlice.reducer