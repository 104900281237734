import { createSlice } from "@reduxjs/toolkit"
import { IClient } from "../../models"
import { fetchClients, fetchClientsWithFilter } from "../actions/clientsActions"

interface IClientStore {
    count: number
    next: string | null
    previous: string | null
    results: IClient[]
    total_pages: number
}

const initialState: IClientStore = {
    count: 0,
    next: null,
    previous: null,
    results: [],
    total_pages: 0
}

export const clientsSlice = createSlice({
    name: "clients",
    reducers: {},
    initialState,
    extraReducers: {
        [fetchClients.fulfilled.type]: (state, action) => {
            state.count = action.payload.count
            state.next = action.payload.next
            state.previous = action.payload.previous
            state.total_pages = action.payload.total_pages
            state.results = action.payload.results
        },
        [fetchClientsWithFilter.fulfilled.type]: (state, action) => {
            state.results = action.payload
        }
    }
})

export default clientsSlice.reducer