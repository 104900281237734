import {Box} from '@mui/material'
import React, {useEffect} from 'react'
import { OrdersTimeline } from '../components/orders'
import { useAppDispatch } from '../hooks/redux'
import { fetchOrders } from '../redux/actions/ordersActions'
import "react-calendar-timeline/lib/Timeline.css";


const OrdersPage = () => {

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchOrders(''))
    }, [])

    return (
        <>
            <Box sx={{ p: { xs: 1, md: 0 }, maxWidth: '1400px', margin: '0 auto' }}>
                <OrdersTimeline />
            </Box>
        </>
    )
}

export default React.memo(OrdersPage)