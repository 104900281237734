import { Box, Button, ButtonGroup, Grid } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { CarCard, CarModal, CarsFilter, CarAddButton } from '../components'
import { useAppDispatch, useAppSelector } from '../hooks/redux'
import { fetchBrandAndModel, fetchCars } from '../redux/actions/carsActions'
import { useNavigate, useParams } from "react-router-dom";
import { useToggle } from "../hooks/use-toggle";
import { useTranslation } from 'react-i18next'

const CarsPage: React.FC = () => {

    const dispatch = useAppDispatch()
    const {
        cars: carsList,
        paginationData: { next: nextPage, previous: prevPage },
    } = useAppSelector(state => state.carsReducer)

    const { id } = useParams()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const modal = useToggle(false)

    const openModal = (carId: number) => {
        navigate(`/app/carslist/${carId}`)
        modal.on()
    }

    useEffect(() => {
        dispatch(fetchCars(''))
        dispatch(fetchBrandAndModel(''))
    }, [])

    const handlePageChange = useCallback((type: "next" | "previous") => {
        if (type === "next") {
            dispatch(fetchCars(nextPage))
        } else {
            dispatch(fetchCars(prevPage))
        }
    }, [nextPage, prevPage])

    return (
        <>
            <Box sx={{ p: { xs: 1, md: 0 }, maxWidth: '1400px', margin: '0 auto' }}>
                <CarsFilter />
                <CarAddButton/>
                <Grid container spacing={5} sx={{ mt: 3 }}>
                    {carsList.map(car => (
                        <Grid item key={car.car_id} xs={12} sm={6} md={4} lg={3}
                            onClick={() => openModal(car.car_id as number)}>
                            <CarCard {...car} />
                        </Grid>
                    ))}
                </Grid>
                <Box className="flex jcc aic">
                    <ButtonGroup variant="outlined" sx={{ mt: 4 }}>
                        <Button
                            onClick={() => handlePageChange('previous')}
                            disabled={!Boolean(prevPage)}
                        >
                            {t('previous')}
                        </Button>
                        <Button
                            onClick={() => handlePageChange('next')}
                            disabled={!Boolean(nextPage)}
                        >
                            {t('next')}
                        </Button>
                    </ButtonGroup>
                </Box>
            </Box>
            {modal.state && <CarModal id={id || "car-id"} modal={modal} key="item" />}
        </>
    )
}

export default React.memo(CarsPage)