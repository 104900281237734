import React from 'react'

const Loader = () => {

    return (
        <div className={`content loading`}>
            <div className="wrapper loading">
                <div className="loader-wrapper">
                    <div className={`loader`}>
                        <span></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Loader)