import axios from 'axios'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getBlackListClients(payload: string) {
    return axios.get(!!payload ? payload : "/api/black_list/");
  },
  createBlackListClient(payload: FormData) {
    return axios.post("/api/black_list/", payload);
  },
  clientsFromBlackFilter(payload: string) {
    return axios.get(`/api/black_list/?search=${payload}`);
  },
  blockClient(payload: FormData){
    return axios.post("/api/black_list/", payload);
  },
  unblockClient(clientId: number){
    return axios.delete(`/api/black_list/${clientId}/?demo=1`);
  },
  editClient(payload: FormData, clientId: number) {
    return axios.put(`/api/black_list/${clientId}/?partial=1`, payload);
  },
};
