import { useEffect } from "react";
import { FieldErrorsImpl } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ITypes } from "../models";
import { appSlice } from "../redux/reducers/appReducer";
import { useAppDispatch } from "./redux";

export function useFormState(errors: FieldErrorsImpl<ITypes>, submitCount: number) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    submitCount !== 0 && Object.keys(errors).length > 0 &&
      dispatch(
        appSlice.actions.toggleSystemAlert({
          severity: "error",
          text: t("fillFields"),
          show: true,
        })
      );
  }, [submitCount]);
}
