import {Box, Card, CardContent, CardMedia, Stack, Typography} from '@mui/material'
import React from 'react'
import { IBlackList } from '../../models'
import no_avatar from '../../assets/no_img.jpg'
// import {useTranslation} from "react-i18next";

interface IProps extends IBlackList {
    isSelected?: boolean
}
const server_url = 'https://4set.uz'

const BlackListClientCard = (props: IProps) => {

    // const {t} = useTranslation()
    const { firstname, lastname, second_name,  passport_series, isSelected, photo } = props
    const upper = (val: string) => {
        return val.toUpperCase()
    }
    return (
           <Card
      sx={{
        cursor: 'pointer',
        borderRadius: 5,
        display: 'flex',
        border: `${isSelected ? '2px' : '1px'} solid ${isSelected ? '#f00' : '#000'}`,
        position: 'relative', // Position relative for pseudo-element
        [`&.true`]: {
          '&::before': {
            content: '"Black list"',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'Nightmare, serif',
            fontSize: '4rem',
            color: 'red',
            transform: 'rotateZ(-30deg)',
          },
        },
      }}
      className={`client true`}
    >
            <CardMedia
                component={"img"}
                height={"153px"}
                sx={{maxWidth: '140px'}}
                image={photo ? `${server_url}${photo}`: no_avatar}
                // image={!!photo ? (Array.isArray(photo) && photo.length > 0 ? `${server_url}${photo[0].file}` : no_avatar) : no_avatar}

            />
            <CardContent sx={{"paddingBottom":"0px !important"}}>
                <Typography variant={"h6"} component={"div"}>
                    {lastname}
                </Typography>
                <Typography variant={"h6"} component={"div"}>
                    {firstname}
                </Typography>
                <Typography variant={"h6"} component={"div"}>
                    {second_name}
                </Typography>
                <Typography variant={"body1"}>
                    {upper(passport_series)}
                </Typography>
                <Stack display="flex" direction="row" className="aic">
                    <Box sx={{
                        width: '8px',
                        height: '8px',
                        borderRadius: '50%',
                        backgroundColor: 'red'
                    }}
                    ></Box>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default React.memo(BlackListClientCard)