import React, {useCallback, useState, ChangeEvent, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {useTimeline} from "../../hooks/use-timeline";
import {useToggle} from "../../hooks/use-toggle";
import Timeline from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import {FormControl, InputLabel, MenuItem, Select, TextField, Grid, Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import {MUI_INPUTS_SIZE} from "../../const";
import {OrderModal, FilterBox} from "./index";
import {Typography} from '@mui/material'
import {fetchFilterOrders, fetchOrders} from "../../redux/actions/ordersActions";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from '@mui/x-date-pickers'
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import ruLocale from "date-fns/locale/ru";
import moment from "moment/moment";
import { fDashedDate } from '../../utils/utils';
import RestoreIcon from '@mui/icons-material/Restore';
import SaveAlt from "@mui/icons-material/SaveAlt";

const OrdersTimeline = () => {

    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const {results: orders} = useAppSelector((state) => state.ordersReducer);
    const open = useToggle(false)
    const [orderId, setOrderId] = useState(null)
    const [selectedOrders, updateSelectedOrders] = useState([]);
    const [FilterDate, setFilterDate] = useState(Date())

    const {
         groups,
        items,
        startDate,
        endMonth,
        setRange,
        range,
        setStartDate,
        setEndMonth
    } = useTimeline(orders);

    const handleInputChange = (_event, newInput) => {
        updateSelectedOrders(newInput);
    };

    useEffect(() => {
        console.log('work')
        console.log(FilterDate)

        const specificDate = new Date(FilterDate)
        let newStartDate = new Date(FilterDate)
        let newEndDate =  new Date(FilterDate)
        newStartDate.setDate(specificDate.getDate() - 15)
        newEndDate.setDate(specificDate.getDate() + 15)
        setStartDate(moment(newStartDate))
        setEndMonth(moment(newEndDate))
  }, [FilterDate])

    const mapTruckNames = () => {
        if (groups){
            return groups.map((group) => group.title);
        }
        return []
    };

    const getGroupsToShow = () => {
        return selectedOrders.length
          ? groups.filter((group) =>
              selectedOrders.includes(group.title)
            )
          : groups;
    };

    const getItemsToShow = () => {
        return items
    }

    const handleRangeChange = (event) => {
        const value = event.target.value
        setRange(Number(value))
        dispatch(fetchFilterOrders({month: value}))
    }
    const handleDateFilter = (start) => {
        const specificDate = new Date(start)
        setFilterDate(start)
        let newStartDate = new Date(start)
        let newEndDate =  new Date(start)
        newStartDate.setDate(specificDate.getDate() - 15)
        newEndDate.setDate(specificDate.getDate() + 15)
        setStartDate(moment(newStartDate))
        setEndMonth(moment(newEndDate))
        dispatch(fetchFilterOrders({date: fDashedDate(start)}))

    }

    const RestoreFilterDate = () => {
        setFilterDate(Date())
        dispatch(fetchOrders(''))
    }

    const orderColor = useCallback((status) => {
        let color = ""
        switch (status) {
            case "in progress":
                return color = "rgba(85, 174, 237, 0.5)"
            case "reserved":
                return color = "rgba(205, 235, 84, 0.5)"
            case "finished":
                return color = "rgba(255, 50, 0, 0.5)"
            default:
                return color = "rgba(85, 174, 237, 0.5)"
        }
    }, [])

    const ItemRenderer = ({ item, timelineContext, itemContext, getItemProps, getResizeProps }) => {

        const backgroundColor = orderColor(item.status)

        const handleOrderSelect = () => {
            open.on()
            setOrderId(item.order_id)
        }

        return (
          <div
            {...getItemProps({
              style: {
                backgroundColor,
                color: "#000",
                borderRadius: 5,
                border: "1px solid black",
              },
              onMouseDown: () => handleOrderSelect(),
            })}
          >
            <Typography
              variant="caption"
              component="h6"
              sx={{ px: 0.5, textOverflow: "ellipsis !important", overflow: "hidden", whiteSpace: "nowrap" }}
            >
              {itemContext.title}
            </Typography>
          </div>
        );
    }

    return (
        <div>
            <Grid container spacing={3} sx={{paddingTop: '10px'}}>
                <Grid item sm={12} md={3}>
                    <FormControl sx={{my: 2}}>
                            <InputLabel>
                                {t('range')}
                            </InputLabel>
                            <Select
                                label={t('range')}
                                onChange={handleRangeChange}
                                value={range}
                                size={MUI_INPUTS_SIZE}
                            >
                                <MenuItem value="1">1 {t('months')}</MenuItem>
                                <MenuItem value="2">2 {t('months')}</MenuItem>
                                <MenuItem value="3">3 {t('months')}</MenuItem>
                                <MenuItem value="4">4 {t('months')}</MenuItem>
                                <MenuItem value="5">5 {t('months')}</MenuItem>
                                <MenuItem value="6">6 {t('months')}</MenuItem>
                                <MenuItem value="12">12 {t('months')}</MenuItem>
                            </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
                        <Grid container spacing={3}>

                        <Grid item sm={12} md={4}>
                             <DatePicker
                                value={FilterDate}
                                onChange={(newValue) => {
                                    handleDateFilter(newValue);
                                }}
                                renderInput={(params) => <TextField size="small" sx={{ mt: 1 }} {...params} />}
                             />
                        </Grid>
                        <Grid item sm={12} md={4} sx={{marginTop: '8px'}}>
                            <Button className={"flex aic jcc"} color="primary" onClick={RestoreFilterDate} variant="outlined"  sx={{height: '40px'}}>
                                <RestoreIcon />
                            </Button>
                        </Grid>
                        </Grid>

                    </LocalizationProvider>
                </Grid>
                <Grid item sm={12} md={3}>

                <div id="combo-box-container">
                    <FilterBox
                        truckNames={mapTruckNames()}
                        onInputChange={handleInputChange}
                        selectedGroups={selectedOrders}
                        label={t('order_filter_by_car_label')}
                        placeholder={t('order_filter_by_car_placeholder')}
                    />
                </div>
                </Grid>
            </Grid>

                <OrderModal
                    open={open.state}
                    onClose={() => open.off()}
                    orderId={orderId}
                    setOrderId={() => setOrderId(null)}
                />
                {
                    (Array.isArray(groups) && Array.isArray(items)) && (
                        <Timeline
                            groups={getGroupsToShow()}
                            items={getItemsToShow()}
                            // visibleTimeStart={startDate}
                            // visibleTimeEnd={endMonth}
                            canMove={false}
                            canChangeGroup={false}
                            canResize={false}
                            stackItems={true}
                            itemRenderer={ItemRenderer}
                            itemTouchSendsClick={true}
                            sidebarWidth={230}
                            itemHeightRatio={0.7}
                            // buffer={1}
                            // sidebarContent={<div>Above The Left</div>}
                            itemsSorted
                            // showCursorLine
                            defaultTimeStart={startDate}
                            defaultTimeEnd={endMonth}

                            // lineHeight={50}
                            timeSteps={{
                                minute: 10,
                                hour: 1,
                                day: 1,
                                month: 1,
                                year: 1
                            }}
                        />
                    )
                }
        </div>
    );
};

export default React.memo(OrdersTimeline);
