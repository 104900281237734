import {IOrder} from "../models";
import {useEffect, useState} from "react";
import moment from "moment";

type IRange = 1 | 2 | 3 | 6

export function useTimeline(orders: IOrder[]) {
  const [range, setRange] = useState<IRange>(3)
  const [startDate, setStartDate] = useState<any>(null)
  const [endMonth, setEndMonth] = useState<any>(null)

  const [groups, setGroups] = useState(null)
  const [items, setItems] = useState(null)

  // useEffect(() => {
  //   const monthToSubtr = 1 - Math.floor(1 / 2)
  //   const newStartDate =
  //     moment()
  //       .add(-monthToSubtr + 1, "months")
  //       .add(-14, "days")
  //   const newEndDate = moment(newStartDate).add(1, 'months')
  //   setEndMonth(newEndDate)
  //   setStartDate(newStartDate)
  // }, [range])

  useEffect(() => {
    fillGroups()
  }, [orders])

  useEffect(() => {
    fillItems()
  }, [groups])

  const fillGroups = () => {
    const body: any = []
    for (let i of orders) {
      const foundCar = body.find((r: any) => r.id === i.car_id)
      if (!foundCar) {
        const newOrder = {
          id: i.car_id,
          title: `${i.brand} ${i.model} ${i.car_num}`
        }
        body.push(newOrder)
      }
    }
    setGroups(body)
  }

  const fillItems = () => {
    const body: any = []
    if (Array.isArray(groups)) {
      for (let i of orders) {
        //@ts-ignore
        const foundGroup = groups.find((group: any) => group.id === i.car_id)
        foundGroup && body.push({
          id: i.timestamp,
          group: foundGroup.id,
          title: `${i.firstname} ${i.lastname} ${i.passport}`,
          start_time: moment(i.start).add(i.start_time).valueOf(),
          end_time: moment(i.is_extended ? i.extended_to : i.end).add(i.end_time).valueOf(),
          order_id: i.order_id,
          status: i.status
        })
      }
    }
    setItems(body)
  }

  return {
    groups,
    items,
    startDate,
    endMonth,
    setRange,
    range,
    setStartDate,
    setEndMonth
  }
}