import * as yup from "yup"

export interface ILogin {
    username: string
    password: string
}

export interface ISuccessLogin {
    token: string
    user_id: number
    username: string
}

export const login: ILogin = {
    username: "",
    password: ""
}

export const loginSchema = yup.object({
    username: yup.string().required(),
    password: yup.string().required()
}).required()