import * as yup from 'yup'

export interface IOil {
  car_id: number
  name: string
  date: string | null
  mileage: number
  oil_mileage: number
  miles_left: number
}

export interface IInsurance {
  car_id: number
  name: string
  series: string
  number: string
  type: string
  costs: string
  from_date: string | null
  to_date: string | null
  days_left?: number
}

export interface ICar {
  brand: string;
  model: string;
  color: string;
  cost: string;
  engine_num: string;
  body_num: string;
  tech_passport: string;
  car_num: string;
  spare_wheel: string;
  battery: string;
  wheels_model: string;
  rent_cost: string;
  toning: string;
  year_of_issue: string;
  car_id?: number
  photo?: any
  mileage: string,
  oil: IOil | null,
  ins: IInsurance | null,
  status?: "vacant" | "occupied"
}

export const carModel: ICar = {
  brand: "Chevrolet",
  model: "",
  color: "",
  cost: "",
  engine_num: "",
  body_num: "",
  tech_passport: "",
  car_num: "",
  spare_wheel: "Long march 435/50R19.5 PR18",
  battery: "EUROSTAT 60",
  wheels_model: "Long march 435/50R19.5 PR18",
  rent_cost: "",
  toning: "нет",
  year_of_issue: "",
  mileage: "0",
  oil: null,
  ins: null
};

export const oilModel: IOil = {
  mileage: 0,
  miles_left: 0,
  car_id: 0,
  name: "",
  oil_mileage: 0,
  date: null
}

export const insuranceModel: IInsurance = {
  car_id: 0,
  name: "",
  series: "",
  number: "",
  type: "",
  costs: "0",
  from_date: null,
  to_date: null,
  days_left: 0
}

export const carSchema = yup.object({
  brand: yup.string().default('Chevrolet').required(),
  model: yup.string().required(),
  color: yup.string().required(),
  cost: yup.number().integer().positive().required(),
  rent_cost: yup.number().integer().positive().required(),
  engine_num: yup.string().required(),
  body_num: yup.string().required(),
  tech_passport: yup.string().required(),
  car_num: yup.string().max(8).required(),
  spare_wheel: yup.string().default("Long march 435/50R19.5 PR18").required(),
  battery: yup.string().default("EUROSTAT 60").required(),
  wheels_model: yup.string().default("Long march 435/50R19.5 PR18").required(),
  toning: yup.string().default("нет").required(),
  year_of_issue: yup.number().integer().positive()
}).required()

export const oilSchema = yup.object({
  mileage: yup.number().positive().integer().required(),
  miles_left: yup.number().positive().integer().required(),
  name: yup.string().required(),
  oil_mileage: yup.number().positive().required(),
  date: yup.string().nullable().required()
}).required()

export const insuranceSchema = yup.object({
  name: yup.string().required(),
  series: yup.string().required(),
  number: yup.string().required(),
  type: yup.string().required(),
  costs: yup.number().integer().positive().required(),
  from_date: yup.string().nullable().required(),
  to_date: yup.string().nullable().required(),
  days_left: yup.number().integer().positive()
}).required()