import React from 'react'
import { Outlet } from 'react-router-dom'
import { NAVBAR_HEIGHT } from '../../const'
import { styled } from '@mui/material/styles';
import Navbar from './Navbar';

const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: NAVBAR_HEIGHT + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
        paddingTop: NAVBAR_HEIGHT + 24,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}));

const MainLayout = () => {
    return (
        <RootStyle>
            <Navbar />
            <MainStyle>
                <Outlet />
            </MainStyle>
        </RootStyle>
    )
}

export default React.memo(MainLayout)