import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { clientModel, clientSchema, IClient } from "../models";
import { useForm } from "react-hook-form";
import {copyObject, fDashedDate} from "../utils/utils";
import {Box, Button, Card, Grid, InputAdornment, Stack} from '@mui/material'
import { Input } from "../components";
import { CustomDatePicker } from '../components/datepicker';
import CountryAutocomplete from '../components/countryAutocomplete/CountryAutocomplete';
import PersonAddAlt from '@mui/icons-material/PersonAddAlt'
import {useAppDispatch} from "../hooks/redux";
import {fetchCreateClient} from "../redux/actions/clientsActions";
import {appSlice} from "../redux/reducers/appReducer";
import {useNavigate} from "react-router-dom";
import { useFormState } from '../hooks/use-form';
import {yupResolver} from '@hookform/resolvers/yup'
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const ClientAdd = () => {

    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    // const [clientImg, setClientImg] = useState<any>(null)
     const [clientImgs, setClientImgs] = useState<any[]>([]);
    const navigate = useNavigate()

    const { handleSubmit, control, setValue, formState: {errors, submitCount} } = useForm<IClient>({
        defaultValues: copyObject(clientModel),
        resolver: yupResolver(clientSchema)
    })

    const service = useFormState(errors, submitCount)

    // const handleClientImg = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     if (event.target.files && event.target.files[0]) {
    //         setClientImg(event.target.files[0])
    //     } else {
    //         setClientImg(null)
    //     }
    // }
    const handleClientImg = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
          const filesArray = Array.from(event.target.files);
          setClientImgs(filesArray);
        } else {
          setClientImgs([]);
        }
      };

    const handleDeleteImage = (index: number) => {
        setClientImgs((prevClientImgs) => {
          const newClientImgs = [...prevClientImgs];
          newClientImgs.splice(index, 1);
          return newClientImgs;
        });
    };

    // const createClient = async (data: IClient) => {
    //     const fd = new FormData()
    //
    //     for (const [key, value] of Object.entries(data)) {
    //         fd.append(key, value)
    //     }
    //
    //     fd.append('photo', clientImg)
    //     const res = await dispatch(fetchCreateClient(fd))
    //     if (res?.status === 201) {
    //         dispatch(appSlice.actions.toggleSystemAlert({
    //             severity: 'success',
    //             text: t('clientCreateSuccess'),
    //             show: true,
    //             autoHide: true
    //         }))
    //         navigate('/app/clientslist')
    //     }
    // }

    const clear_phone = (phone: string) => {
          return `${phone.slice(0, 3)}${phone.slice(4, 6)}${[phone.slice(8, 12)]}-${phone.slice(13, 15)}${phone.slice(16, 18)}`;
    }
    const createClient = async (data: IClient) => {
    const fd = new FormData();

    for (const [key, value] of Object.entries(data)) {
        // if (key === 'phone') {
        //     fd.append(key, clear_phone(value));
        // }
        // else {
            fd.append(key, value);
        // }
    }
    // fd.append('photo', clientImgs)
    clientImgs.forEach((image, index) => {
      fd.append('photo', image);
    });

    const res = await dispatch(fetchCreateClient(fd));
        if (res?.status === 201) {
          dispatch(
            appSlice.actions.toggleSystemAlert({
              severity: 'success',
              text: t('clientCreateSuccess'),
              show: true,
              autoHide: true,
            })
          );
          navigate('/app/clientslist');
        }
    };


      const [phoneNumber, setPhoneNumber] = useState('998');

      const formatPhoneNumber = (inputValue: any) => {
        // Remove all non-numeric characters
          console.log(inputValue)
        const numericValue = inputValue.replace(/\D/g, '');

        // Check if the numericValue is not empty and has at least 9 digits
        if (numericValue && numericValue.length >= 12) {
          const formattedValue = `${numericValue.slice(0, 3)}(${numericValue.slice(3, 5)})-${numericValue.slice(5, 8)}-${numericValue.slice(8, 10)}-${numericValue.slice(10, 12)}`;
          setPhoneNumber(formattedValue);
        } else {
          setPhoneNumber(numericValue);
        }
      };
     const handleInputChange = (event: any) => {
         formatPhoneNumber(event.target.value);
         setValue('phone', event.target.value);
     };

    return (
        <Stack sx={{ maxWidth: '1400px', margin: '0 auto' }}>
            <form onSubmit={handleSubmit(createClient)}>
                <Card sx={{ p: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Input
                                label={t('lastName')}
                                name="lastname"
                                control={control}
                                errors={errors}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Input
                                label={t('firstName')}
                                name="firstname"
                                control={control}
                                errors={errors}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Input
                                label={t('fathersName')}
                                name="second_name"
                                control={control}
                                errors={errors}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Input
                                label={t('passport')}
                                name="passport_series"
                                control={control}
                                errors={errors}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <CustomDatePicker
                                control={control}
                                errors={errors}
                                name="date_of_issue"
                                label={t('dateofissue')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <CustomDatePicker
                                control={control}
                                errors={errors}
                                name="date_of_birth"
                                label={t('dateofbirth')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <CountryAutocomplete
                                setValue={setValue}
                                control={control}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Input
                                label={t('authorityTitle')}
                                name="issued"
                                control={control}
                                errors={errors}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Input
                                label={t('address')}
                                name="address"
                                control={control}
                                errors={errors}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Input
                                label={t('telephone')}
                                name="phone"
                                control={control}
                                onChange={handleInputChange}
                                value={phoneNumber}
                                errors={errors}
                                fullWidth={true}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">+</InputAdornment>,
                                }}
                            />
                        </Grid>
                        {/*<Grid item xs={12} sm={6} md={4}>*/}
                        {/*    <Button variant="outlined" component="label" color="primary" aria-label="upload image">*/}
                        {/*        <input hidden accept="image/*" type="file" onChange={handleClientImg} />*/}
                        {/*        {t('uploadPhoto')} &nbsp; <PersonAddAlt />*/}
                        {/*    </Button>*/}
                        {/*</Grid>*/}
                        {/*{*/}
                        {/*    clientImg && (*/}
                        {/*        <Grid item xs={12} sm={6} md={4}>*/}
                        {/*            <Box sx={{maxWidth: '20rem', height: '15rem', border: '1px solid black'}}>*/}
                        {/*                <img style={{objectFit: 'cover', width: '100%', height: '100%'}} src={URL.createObjectURL(clientImg)} alt="Selected Client Image"/>*/}
                        {/*            </Box>*/}
                        {/*        </Grid>*/}
                        {/*    )*/}
                        {/*}*/}
                            <Grid item xs={12} sm={6} md={4}>
                              <Button variant="outlined" component="label" color="primary" aria-label="upload image">
                                <input hidden accept="image/*" type="file" onChange={handleClientImg} multiple />
                                {t('uploadPhoto')} &nbsp; <PersonAddAlt />
                              </Button>
                            </Grid>
                        <Grid container spacing={2} sx={{ paddingLeft: '16px', paddingTop: '16px' }}>
                            {/* Display selected client images */}
                          {clientImgs.map((image, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                              <Box sx={{ position: 'relative', maxWidth: '30rem', height: '15rem', border: '1px solid black' }}>
                                <img
                                  style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                                  src={URL.createObjectURL(image)}
                                  alt={`Selected Client Image ${index}`}
                                />
                                <IconButton
                                  sx={{
                                    position: 'absolute',
                                    top: '5px',
                                    right: '5px',
                                    backgroundColor: 'white',
                                    '&:hover': {
                                        color: 'red', // Change the hover color here
                                      },
                                  }}
                                  onClick={() => handleDeleteImage(index)}
                                >
                                  <ClearIcon />
                                </IconButton>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                        {/*<Grid container spacing={2} sx={{ paddingLeft: '16px', paddingTop: '16px' }}>*/}
                        {/*    /!* Display selected client images *!/*/}
                        {/*    {clientImgs.map((image, index) => (*/}
                        {/*      <Grid item xs={12} sm={6} md={4} key={index}>*/}
                        {/*        <Box sx={{ maxWidth: '30rem', height: '15rem', border: '1px solid black' }}>*/}
                        {/*          <img*/}
                        {/*            style={{ objectFit: 'cover', width: '100%', height: '100%' }}*/}
                        {/*            src={URL.createObjectURL(image)}*/}
                        {/*            alt={`Selected Client Image ${index}`}*/}
                        {/*          />*/}
                        {/*        </Box>*/}
                        {/*      </Grid>*/}
                        {/*    ))}*/}
                        {/*</Grid>*/}
                    </Grid>
                    <Button type="submit" sx={{mt: 2}} variant="outlined" color="success">
                        {t('Create')}
                    </Button>
                </Card>
            </form>
        </Stack>
    )
}

export default React.memo(ClientAdd)