import { createAsyncThunk } from "@reduxjs/toolkit";
import api from '../../api/carrepair'
import {RootDispatch} from "../reducers/RootReducer";

const SERVER_ERROR = 'Ошибка на стороне сервера'

export const fetchRepairedCars = createAsyncThunk(
    'carrepair/fetchAll',
    async (payload: any, thunkAPI) => {
        try {
            const res = await api.getCarRepair(payload)
            return res.data
        } catch (e) {
            return thunkAPI.rejectWithValue(SERVER_ERROR)
        }
    }
)

export const fetchCarNums = createAsyncThunk(
    'carrepair/fetchCarNums',
    async (payload: any, thunkAPI) => {
        try {
            const res = await api.fetchCarNums(payload)
            return res.data
        } catch (e) {
            return thunkAPI.rejectWithValue(SERVER_ERROR)
        }
    }
)



//
export const fetchRepairedCarsWithFilter = createAsyncThunk(
    'black_list/fetchWithFilter',
    async (payload: any, thunkAPI) => {
        try {
            const res = await api.carsFromCarRepairFilter(payload)
            return res.data
        } catch (e) {
            return thunkAPI.rejectWithValue(SERVER_ERROR)
        }
    }
)
//
export const fetchEditCarRepair = (data: FormData, entity_id: number) => async (dispatch: RootDispatch) => {
    try {
        const res = await api.editCarRepair(data, entity_id)
        if (res.status === 200) {
            dispatch(fetchRepairedCars(''))
        }
        return res
    } catch (e) {
        console.log(e)
    }
}
// //
// // export const fetchEditClientPhoto = (photoId: number, file: FormData) => async (dispatch: RootDispatch) => {
// //     try {
// //         return await api.editClientPhoto(photoId, file);
// //     } catch (error) {
// //         console.log(error)
// //     }
// // }
// //
export const fetchCreateCarRepair = (data: FormData) => async (dispatch: RootDispatch) => {
    try {
        return await api.createCarRepair(data)
    } catch (e) {
        console.log(e)
    }
}

export const fetchDeleteCarRepair = (carRepairId: any) => async (dispatch: RootDispatch) => {
    try {
        return await api.deleteCarRepair(carRepairId)
    } catch (e) {
        console.log(e)
    }
}

export const fetchDownloadExcel = (payload: any) => async (dispatch: RootDispatch) => {
    try {
        return await api.fetchDownloadExcel(payload)
    } catch (e) {
        console.log(e)
    }
}
//
// export const fetchBlockClient = (data: FormData) => async (dispatch: RootDispatch) => {
//     try {
//         return await api.blockClient(data)
//     } catch (e) {
//         console.log(e)
//     }
// }
//
// export const fetchUnBlockClient = (id: number) => async (dispatch: RootDispatch) => {
//     try {
//         return await api.unblockClient(id)
//     } catch (e) {
//         console.log(e)
//     }
// }