import { format } from "date-fns";
import ruLocale from 'date-fns/locale/ru'
import enLocal from 'date-fns/locale/en-US'
import uzLocal from 'date-fns/locale/uz'
import {AxiosError} from "axios";
import i18n from 'i18next'

const locales = {
  "en": enLocal,
  "ru": ruLocale,
  "uz": uzLocal
}

export const carNumberFormatter = (num: string) => {
  if (Boolean(Number(num.slice(0, 3)))) {
    let mask2 = "xxx xxx";

    for (let i of num) {
      mask2.replace("x", i);
    }
    return mask2;
  } else {
    let mask1 = "xx xxx xx";

    for (let i of num) {
      mask1.replace("x", i);
    }
    return mask1;
  }
};

export const fCurrency = (price: string) => {
  const val = Number(price);
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const copyObject = (data: any) => {
  return JSON.parse(JSON.stringify(data));
};

export function fDashedDate(date: any) {
  if (date) {
    if (JSON.stringify(date) !== 'null') {
      return format(new Date(date), 'yyyy-MM-dd')
    }
  } else { return date }
}

export function fDate(date: any, lang: string) {
  //@ts-ignore
  return format(new Date(date), 'dd-MMMM-yyyy', {locale: locales[lang]})
}

export const fTime = (time: any) => {
  if (time) {
    if (JSON.stringify(time) !== 'null') {
      return format(new Date(time), 'HH:mm')
    }
  } else { return time }
}

export function ObjectByString (o: any, s: any) {
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  var a = s.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
          o = o[k];
      } else {
          return;
      }
  }
  return o;
};

export function errorMessageHandler(error: AxiosError) {
  // @ts-ignore
  const errors = Object.values(error?.response?.data)
  // @ts-ignore
  return errors.map((err, index) => `${index + 1}. ${i18n.t('errors.' + err[0])}`)
}