import axios from "axios"

export default {
    getCars(payload: string) {
        return axios.get(!!payload ? payload : '/api/cars/')
    },
    createCar(payload: FormData) {
        return axios.post('/api/cars/', payload)
    },
    editCar(payload: FormData, carId: number) {
        return axios.put(`/api/cars/${carId}/`, payload)
    },
    brandAndModel() {
        return axios.get('/api/brand&model/')
    },
    carsFilter(params: any) {
        return axios.get('/api/cars', {params})
    },
    deleteCar(carId: number) {
        return axios.delete(`/api/cars/${carId}/`)
    },
    getOilByCarId(carId: number) {
        return axios.get(`/api/car/oil/${carId}/`)
    },
    addOil(payload: FormData) {
        return axios.post(`/api/car/oil/`, payload)
    },
    addInsurance(payload: FormData) {
        return axios.post(`/api/car/insurance/`, payload)
    },
    updateOil(payload: FormData, carId: number) {
        return axios.put(`/api/car/oil/${carId}/`, payload)
    },
    deleteOil(carId: number) {
        return axios.delete(`/api/car/oil/${carId}/`)
    },
    updateInsurance(payload: FormData, carId: number) {
        return axios.put(`/api/car/insurance/${carId}/`, payload)
    },
    deleteInsurance(carId: number) {
        return axios.delete(`/api/car/insurance/${carId}`)
    }
}