import {createSlice} from "@reduxjs/toolkit";
import {ICar} from "../../models";
import {fetchBrandAndModel, fetchCars, fetchCarsWithFilter} from "../actions/carsActions";

interface ICarStore {
    cars: ICar[],
    paginationData: {
        total_pages: number
        next: string
        previous: string
    },
    filter: {
        min: number
        max: number
    },
    modelbrand: []
}

const initialState: ICarStore = {
    cars: [],
    paginationData: {
        total_pages: 1,
        next: "",
        previous: "",
    },
    filter: {
        max: 0,
        min: 0,
    },
    modelbrand: [],
};

export const carsSlice = createSlice({
    name: "cars",
    initialState,
    reducers: {},
    extraReducers: {
        [fetchCars.fulfilled.type]: (state, action) => {
            state.cars = action.payload.results
            state.paginationData.total_pages = action.payload.total_pages
            state.paginationData.next = action.payload.next
            state.paginationData.previous = action.payload.previous
        },
        [fetchBrandAndModel.fulfilled.type]: (state, action) => {
            state.modelbrand = action.payload
        },
        [fetchCarsWithFilter.fulfilled.type]: (state, action) => {
            state.cars = action.payload.results
        }
    }
})

export default carsSlice.reducer