import {Box, Card, CardContent, CardMedia, Stack, Typography} from '@mui/material'
import React from 'react'
import { IClient } from '../../models'
import no_avatar from '../../assets/no_img.jpg'
import {useTranslation} from "react-i18next";

interface IProps extends IClient {
    isSelected?: boolean
}
const server_url = 'https://4set.uz'



const ClientCard = (props: IProps) => {

    const {t} = useTranslation()
    const { firstname, lastname, passport_series, photo, isSelected, status, in_black_list } = props

    return (
        // <Card
        //     sx={{ cursor: 'pointer', borderRadius: 5, display: 'flex', border: `${isSelected ? '2px' : '1px'} solid ${isSelected ? '#f00' : '#000'}` }}>
        //     className={`client ${in_black_list}`}
           <Card
      sx={{
        cursor: 'pointer',
        borderRadius: 5,
        display: 'flex',
        border: `${isSelected ? '2px' : '1px'} solid ${isSelected ? '#f00' : '#000'}`,
        position: 'relative', // Position relative for pseudo-element
        [`&.${in_black_list}`]: {
          '&::before': {
            content: '"Black list"',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'Nightmare, serif',
            fontSize: '4rem',
            color: 'red',
            transform: 'rotateZ(-30deg)',
          },
        },
      }}
      className={`client ${in_black_list ? 'true' : ''}`}
    >
            <CardMedia
                component={"img"}
                height={"153px"}
                sx={{maxWidth: '140px'}}
                image={!!photo ? (Array.isArray(photo) && photo.length > 0 ? `${server_url}${photo[0].file}` : no_avatar) : no_avatar}
            />
            <CardContent>
                <Typography variant={"h6"} component={"div"}>
                    {firstname}
                </Typography>
                <Typography variant={"h6"} component={"div"}>
                    {lastname}
                </Typography>
                <Typography variant={"body1"}>
                    {passport_series}
                </Typography>
                <Stack display="flex" direction="row" className="aic">
                    <Box sx={{
                        width: '8px',
                        height: '8px',
                        borderRadius: '50%',
                        backgroundColor: status === 'vacant' ? 'green' : 'red'
                    }}
                    ></Box>
                    <Typography sx={{ml: 1}}>
                        {t(status as string)}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default React.memo(ClientCard)