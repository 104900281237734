import * as yup from 'yup'

export interface ICarRepair {
    id: string
    model: string
    car_num: string
    date: Date
    reason: string
    cost: string
    created_at?: string
}

export const ICarRepairModel = {
    car_id: 0,
    date: "",
    reason: "",
    cost: "",
}

export const carrepairSchema = yup.object({
    car_id: yup.number().required(),
    date: yup.string().min(5).max(20).required(),
    reason: yup.string().required(),
    cost: yup.string().required(),
}).required()
