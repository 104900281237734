import { ICreateOrder } from '../models';
import axios from 'axios'

// eslint-disable-next-line
export default {
    getOrders() {
        return axios.get('/api/orders/?month=3')
    },
    ordersFilter(params: any) {
        return axios.get(`/api/orders/`, {params});
    },
    createOrder(payload: ICreateOrder) {
        return axios.post(`/api/orders/`, payload)
    },
    getFile(orderId: number, lang: String) {
        return axios.get(`/api/getfile?order_id=${orderId}&lang=${lang}`)
    },
    editOrder(payload: FormData, orderId: number) {
        return axios.put(`/api/orders/${orderId}/`, payload)
    },
    extendOrder(payload: FormData) {
        return axios.post(`/api/extended_order/`, payload)
    }
}