import { Stack } from '@mui/material'
import React from 'react'

const Entry = () => {
    return (
        <Stack className='flex jcc aic'>
            <div className="flex jcc aic">
                <svg id="ft" width="1339" height="1083" viewBox="0 0 1339 1083" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 206L627 5V1078L456 1021V645L167.5 726.5V540.5L456 451.5V263L4 402V206Z" stroke="#4169E1" strokeWidth="25" />
                    <path d="M1335 211.849L676 5V1078L844.148 1026.16V270.23L1335 423.228V211.849Z" stroke="#4169E1" strokeWidth="25" />
                </svg>

                <svg id="logo" width="400" height="101" viewBox="0 0 400 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.24 96.72C7.48 96.72 6 96.12 4.8 94.92C3.6 93.72 3 92.24 3 90.48V9.24C3 7.4 3.6 5.92 4.8 4.8C6 3.6 7.48 3 9.24 3H63.48C65.32 3 66.8 3.56 67.92 4.68C69.04 5.72 69.6 7.12 69.6 8.88C69.6 10.56 69.04 11.92 67.92 12.96C66.8 14 65.32 14.52 63.48 14.52H15.48V43.68H49.92C51.76 43.68 53.24 44.24 54.36 45.36C55.48 46.4 56.04 47.76 56.04 49.44C56.04 51.12 55.48 52.52 54.36 53.64C53.24 54.68 51.76 55.2 49.92 55.2H15.48V90.48C15.48 92.24 14.88 93.72 13.68 94.92C12.48 96.12 11 96.72 9.24 96.72Z" strokeWidth="5" mask="url(#path-1-outside-1)" />
                    <path d="M114.12 97.2C107.64 97.2 101.92 95.8 96.96 93C92 90.12 88.08 86.2 85.2 81.24C82.4 76.2 81 70.44 81 63.96C81 57.4 82.4 51.64 85.2 46.68C88.08 41.64 92 37.72 96.96 34.92C101.92 32.04 107.64 30.6 114.12 30.6C120.52 30.6 126.2 32.04 131.16 34.92C136.12 37.72 140 41.64 142.8 46.68C145.68 51.64 147.12 57.4 147.12 63.96C147.12 70.44 145.72 76.2 142.92 81.24C140.12 86.2 136.24 90.12 131.28 93C126.32 95.8 120.6 97.2 114.12 97.2ZM114.12 86.4C118.28 86.4 121.96 85.44 125.16 83.52C128.36 81.6 130.84 78.96 132.6 75.6C134.44 72.24 135.36 68.36 135.36 63.96C135.36 59.56 134.44 55.68 132.6 52.32C130.84 48.88 128.36 46.2 125.16 44.28C121.96 42.36 118.28 41.4 114.12 41.4C109.96 41.4 106.28 42.36 103.08 44.28C99.88 46.2 97.36 48.88 95.52 52.32C93.68 55.68 92.76 59.56 92.76 63.96C92.76 68.36 93.68 72.24 95.52 75.6C97.36 78.96 99.88 81.6 103.08 83.52C106.28 85.44 109.96 86.4 114.12 86.4Z" strokeWidth="5" mask="url(#path-1-outside-1)" />
                    <path d="M171.337 96.72C167.337 96.72 165.337 94.72 165.337 90.72V37.08C165.337 33.08 167.337 31.08 171.337 31.08C175.337 31.08 177.337 33.08 177.337 37.08V39.36C179.737 36.56 182.657 34.36 186.097 32.76C189.617 31.16 193.417 30.36 197.497 30.36C202.297 30.36 205.857 31.16 208.177 32.76C210.577 34.28 211.497 36.12 210.937 38.28C210.537 39.96 209.737 41.08 208.537 41.64C207.337 42.12 205.937 42.2 204.337 41.88C199.217 40.84 194.617 40.76 190.537 41.64C186.457 42.52 183.217 44.16 180.817 46.56C178.497 48.96 177.337 52 177.337 55.68V90.72C177.337 94.72 175.337 96.72 171.337 96.72Z" strokeWidth="5" mask="url(#path-1-outside-1)" />
                    <path d="M247.576 97.2C241.976 97.2 236.776 96.36 231.976 94.68C227.256 92.92 223.576 90.72 220.936 88.08C219.736 86.8 219.216 85.36 219.376 83.76C219.616 82.08 220.416 80.72 221.776 79.68C223.376 78.4 224.936 77.88 226.456 78.12C228.056 78.28 229.416 78.96 230.536 80.16C231.896 81.68 234.056 83.12 237.016 84.48C240.056 85.76 243.416 86.4 247.096 86.4C251.736 86.4 255.256 85.64 257.656 84.12C260.136 82.6 261.416 80.64 261.496 78.24C261.576 75.84 260.416 73.76 258.016 72C255.696 70.24 251.416 68.8 245.176 67.68C237.096 66.08 231.216 63.68 227.536 60.48C223.936 57.28 222.136 53.36 222.136 48.72C222.136 44.64 223.336 41.28 225.736 38.64C228.136 35.92 231.216 33.92 234.976 32.64C238.736 31.28 242.656 30.6 246.736 30.6C252.016 30.6 256.696 31.44 260.776 33.12C264.856 34.8 268.096 37.12 270.496 40.08C271.616 41.36 272.136 42.72 272.056 44.16C271.976 45.52 271.296 46.68 270.016 47.64C268.736 48.52 267.216 48.8 265.456 48.48C263.696 48.16 262.216 47.44 261.016 46.32C259.016 44.4 256.856 43.08 254.536 42.36C252.216 41.64 249.536 41.28 246.496 41.28C242.976 41.28 239.976 41.88 237.496 43.08C235.096 44.28 233.896 46.04 233.896 48.36C233.896 49.8 234.256 51.12 234.976 52.32C235.776 53.44 237.296 54.48 239.536 55.44C241.776 56.32 245.056 57.2 249.376 58.08C258.416 59.84 264.656 62.36 268.096 65.64C271.616 68.84 273.376 72.8 273.376 77.52C273.376 81.2 272.376 84.52 270.376 87.48C268.456 90.44 265.576 92.8 261.736 94.56C257.976 96.32 253.256 97.2 247.576 97.2Z" strokeWidth="5" mask="url(#path-1-outside-1)" />
                    <path d="M319.692 97.2C313.052 97.2 307.132 95.8 301.932 93C296.812 90.12 292.772 86.2 289.812 81.24C286.932 76.2 285.492 70.44 285.492 63.96C285.492 57.4 286.852 51.64 289.572 46.68C292.372 41.64 296.212 37.72 301.092 34.92C305.972 32.04 311.572 30.6 317.892 30.6C324.132 30.6 329.492 32 333.972 34.8C338.452 37.52 341.852 41.32 344.172 46.2C346.572 51 347.772 56.56 347.772 62.88C347.772 64.4 347.252 65.68 346.212 66.72C345.172 67.68 343.852 68.16 342.252 68.16H296.652C297.532 73.68 300.052 78.2 304.212 81.72C308.452 85.16 313.612 86.88 319.692 86.88C322.172 86.88 324.692 86.44 327.252 85.56C329.892 84.6 332.012 83.52 333.612 82.32C334.812 81.44 336.092 81 337.452 81C338.892 80.92 340.132 81.32 341.172 82.2C342.532 83.4 343.252 84.72 343.332 86.16C343.412 87.6 342.772 88.84 341.412 89.88C338.692 92.04 335.292 93.8 331.212 95.16C327.212 96.52 323.372 97.2 319.692 97.2ZM317.892 40.92C311.972 40.92 307.212 42.56 303.612 45.84C300.012 49.12 297.732 53.36 296.772 58.56H336.732C336.012 53.44 334.052 49.24 330.852 45.96C327.652 42.6 323.332 40.92 317.892 40.92Z" strokeWidth="5" mask="url(#path-1-outside-1)" />
                    <path d="M387.263 96.72C383.263 96.72 379.663 95.72 376.463 93.72C373.263 91.64 370.743 88.84 368.903 85.32C367.063 81.8 366.143 77.84 366.143 73.44V43.8H360.623C358.943 43.8 357.583 43.32 356.543 42.36C355.503 41.4 354.983 40.2 354.983 38.76C354.983 37.16 355.503 35.88 356.543 34.92C357.583 33.96 358.943 33.48 360.623 33.48H366.143V16.2C366.143 14.44 366.703 13 367.823 11.88C368.943 10.76 370.383 10.2 372.143 10.2C373.903 10.2 375.343 10.76 376.463 11.88C377.583 13 378.143 14.44 378.143 16.2V33.48H388.343C390.023 33.48 391.383 33.96 392.423 34.92C393.463 35.88 393.983 37.16 393.983 38.76C393.983 40.2 393.463 41.4 392.423 42.36C391.383 43.32 390.023 43.8 388.343 43.8H378.143V73.44C378.143 76.64 379.023 79.32 380.783 81.48C382.543 83.64 384.703 84.72 387.263 84.72H391.343C392.783 84.72 393.983 85.28 394.943 86.4C395.983 87.52 396.503 88.96 396.503 90.72C396.503 92.48 395.823 93.92 394.463 95.04C393.183 96.16 391.503 96.72 389.423 96.72H387.263Z" strokeWidth="5" mask="url(#path-1-outside-1)" />
                </svg>
            </div>
        </Stack>
    )
}

export default React.memo(Entry)