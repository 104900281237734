import React from 'react'
import {ICar, IInsurance, insuranceModel, insuranceSchema} from "../../models";
import {Box, Button, ButtonGroup, Stack, Typography} from "@mui/material";
import {useForm} from "react-hook-form";
import {copyObject, fCurrency, fDate} from "../../utils/utils";
import {useAppDispatch} from "../../hooks/redux";
import {useToggle} from "../../hooks/use-toggle";
import {useTranslation} from "react-i18next";
import Input from "../input";
import DatePicker from "../datepicker/DatePicker";
import Add from "@mui/icons-material/Add";
import {fetchAddInsInfo, fetchDeleteCarInsurance, fetchUpdateCarInsurance} from "../../redux/actions/carsActions";
import {appSlice} from "../../redux/reducers/appReducer";
import { useFormState } from '../../hooks/use-form';
import {yupResolver} from '@hookform/resolvers/yup'
import i18next from 'i18next';

const Insurance = (props: ICar) => {

    const {car_id, ins} = props
    const dispatch = useAppDispatch()
    const {t} = useTranslation()
    const edit = useToggle(false)

    const {control, handleSubmit, formState: {errors, submitCount}} = useForm<IInsurance>({
        defaultValues: !!ins ? copyObject(ins) : copyObject(insuranceModel),
        resolver: yupResolver(insuranceSchema)
    })

    const service = useFormState(errors, submitCount)

    const createInsInfo = (data: IInsurance) => {
        sendInsInfo(data, false)
    }

    const updateInsInfo = (data: IInsurance) => {
        sendInsInfo(data, true)
    }

    const sendInsInfo = async (data: IInsurance, upd: boolean) => {
        data.car_id = car_id as number

        // @ts-ignore
        upd && delete data.car_id

        const fd = new FormData()

        for (const [key, value] of Object.entries(data)) {
            fd.append(key, value)
        }

        const res = upd ? await dispatch(fetchUpdateCarInsurance(fd, car_id as number)) : await dispatch(fetchAddInsInfo(fd))
        if ([200, 201].includes(res?.status as number)) {
            dispatch(appSlice.actions.toggleSystemAlert({
                severity: "success",
                show: true,
                autoHide: true,
                text: upd ? t('insUpdSuccess') : t('insAddSuccess')
            }))
        }
    }

    const deleteIns = async () => {
        const conf = window.confirm(t('delete') + '?')
        if (conf) {
            const res = await dispatch(fetchDeleteCarInsurance(car_id as number))
            if (res?.status === 204) {
                dispatch(appSlice.actions.toggleSystemAlert({
                    severity: "info",
                    show: true,
                    autoHide: true,
                    text: t('insDelete')
                }))
            }
        }
    }

    return (
        <Box className="flex jcc aic col">
            {
                edit.state ? (
                    <form onSubmit={handleSubmit(createInsInfo)}>
                        <Stack direction={"column"} sx={{p: 2}}>
                            <Input
                                label={t('insName')}
                                control={control}
                                name="name"
                                errors={errors}
                                fullWidth
                                sx={{mt: 2}}
                            />
                            <Input
                                label={t('insSeries')}
                                name="series"
                                control={control}
                                fullWidth
                                errors={errors}
                                sx={{mt: 2}}
                            />
                            <Input
                                label={t('number')}
                                name="number"
                                control={control}
                                fullWidth
                                errors={errors}
                                sx={{mt: 2}}
                            />
                            <Input
                                label={t('insType')}
                                name="type"
                                control={control}
                                fullWidth
                                errors={errors}
                                sx={{mt: 2}}
                            />
                            <Input
                                label={t('cost')}
                                name="costs"
                                control={control}
                                fullWidth
                                errors={errors}
                                sx={{mt: 2}}
                            />
                            <Input
                                label={t('days_left')}
                                name="days_left"
                                control={control}
                                fullWidth
                                errors={errors}
                                type={"number"}
                                sx={{mt: 2}}
                            />
                            <DatePicker
                                label={t('from')}
                                control={control}
                                errors={errors}
                                //@ts-ignore
                                name={"from_date"}
                                fullWidth
                                sx={{mt: 2}}
                            />
                            <DatePicker
                                label={t('to')}
                                control={control}
                                errors={errors}
                                //@ts-ignore
                                name={"to_date"}
                                fullWidth
                                sx={{mt: 2}}
                            />
                            <ButtonGroup variant={"outlined"} sx={{mt: 2}}>
                                <Button color={"error"} onClick={() => deleteIns()}>
                                    {t('delete')}
                                </Button>
                                {
                                    !!ins ? (
                                        <Button onClick={handleSubmit(updateInsInfo)}>
                                            {t('update')}
                                        </Button>
                                    ) : (
                                        <Button type={"submit"} variant={"outlined"}>
                                            {t('save')}
                                        </Button>
                                    )
                                }
                            </ButtonGroup>
                        </Stack>
                    </form>
                ) : (
                    !!ins ? (
                        <>
                            <Box className="flex row jcc aic">
                                <Typography variant="h6" component="h6">
                                    {t('insName')}: &nbsp;
                                </Typography>
                                <Typography component={"h6"} variant="body1">
                                    {ins.name}
                                </Typography>
                            </Box>
                            <Box className="flex row jcsb aic">
                                <Typography variant="h6" component="h6">
                                    {t('insSeries')}: &nbsp;
                                </Typography>
                                <Typography component={"h6"} variant="body1">
                                    {ins.series}
                                </Typography>
                            </Box>
                            <Box className="flex jcsb aic">
                                <Typography variant="h6" component="h6">
                                    {t('number')}: &nbsp;
                                </Typography>
                                <Typography component={"h6"} variant="body1">
                                    {ins.number}
                                </Typography>
                            </Box>
                            <Box className="flex jcsb aic">
                                <Typography variant="h6" component="h6">
                                    {t('insType')}: &nbsp;
                                </Typography>
                                <Typography component={"h6"} variant="body1">
                                    {ins.type}
                                </Typography>
                            </Box>
                            <Box className="flex jcsb aic">
                                <Typography variant="h6" component="h6">
                                    {t('cost')}: &nbsp;
                                </Typography>
                                <Typography component={"h6"} variant="body1">
                                    {fCurrency(ins.costs)}
                                </Typography>
                            </Box>
                            <Box className="flex jcsb aic">
                                <Typography variant="h6" component="h6">
                                    {t('days_left')}: &nbsp;
                                </Typography>
                                <Typography component={"h6"} variant="body1">
                                    {ins.days_left}
                                </Typography>
                            </Box>
                            <Box className="flex jcsb aic">
                                <Typography variant="h6" component="h6">
                                    {t('from')}: &nbsp;
                                </Typography>
                                <Typography component={"h6"} variant="body1">
                                    {fDate(ins.from_date, i18next.language)}
                                </Typography>
                            </Box>
                            <Box className="flex jcsb aic">
                                <Typography variant="h6" component="h6">
                                    {t('to')}: &nbsp;
                                </Typography>
                                <Typography component={"h6"} variant="body1">
                                    {fDate(ins.to_date, i18next.language)}
                                </Typography>
                            </Box>
                            <Box className="flex jcsb aic">
                                <ButtonGroup variant={"outlined"}>
                                    <Button color={"error"} onClick={() => deleteIns()}>
                                        {t('delete')}
                                    </Button>
                                    <Button onClick={() => edit.toggle()}>
                                        {t('update')}
                                    </Button>
                                </ButtonGroup>
                            </Box>
                            </>
                    ) : (
                        <Button onClick={() => edit.toggle()}>
                            <Add/> {t('addInsInfo')}
                        </Button>
                    )
                )

            }
        </Box>
    )
}

export default React.memo(Insurance)