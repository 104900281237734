import { createAsyncThunk } from "@reduxjs/toolkit";
import api from '../../api/cars'
import {RootDispatch} from "../reducers/RootReducer";
import {appSlice} from "../reducers/appReducer";
import {errorMessageHandler} from "../../utils/utils";

const SERVER_ERROR = 'Ошибка на стороне сервера'

export const fetchCars = createAsyncThunk(
    'cars/fetchAll',
    async (payload: any, thunkAPI) => {
        try {
            const res = await api.getCars(payload)
            return res.data
        } catch (e) {
            return thunkAPI.rejectWithValue(SERVER_ERROR)
        }
    }
)

export const fetchCarsWithFilter = createAsyncThunk(
    'cars/fetchWithFilter',
    async (payload: any, thunkAPI) => {
        try {
            const res = await api.carsFilter(payload)
            return res.data
        } catch (e) {
            return thunkAPI.rejectWithValue(SERVER_ERROR)
        }
    }
)

export const fetchBrandAndModel = createAsyncThunk(
    'cars/brandAndModel',
    async (payload: any, thunkAPI) => {
        try {
            const res = await api.brandAndModel()
            return res.data
        } catch (e) {
            return thunkAPI.rejectWithValue(SERVER_ERROR)
        }
    }
)

export const fetchDeleteCar = (carId: number) => async (dispatch: RootDispatch) => {
    try {
        return await api.deleteCar(carId)
    } catch (e) {
        console.log(e)
    }
}

export const fetchCreateCar = (data: FormData) => async (dispatch: RootDispatch) => {
    try {
        return await api.createCar(data)
    } catch (e) {
        console.log(e)
        dispatch(appSlice.actions.toggleSystemAlert({
            severity: 'error',
            // @ts-ignore
            text: errorMessageHandler(e),
            show: true,
            autoHide: false
        }))
    }
}

export const fetchCarOilById = (carId: number) => async (dispatch: RootDispatch) => {
    try {
        return await api.getOilByCarId(carId)
    } catch (e) {
        console.log(e)
    }
}

export const fetchAddCarOil = (payload: FormData) => async (dispatch: RootDispatch) => {
    try {
        const res = await api.addOil(payload)
        if (res?.status === 201) {
            dispatch(fetchCars(''))
        }
        return res
    } catch (e) {
        console.log(e)
    }
}

export const fetchDeleteCarOil = (carId: number) => async (dispatch: RootDispatch) => {
    try {
        const res = await api.deleteOil(carId)
        if (res?.status === 204) {
            dispatch(fetchCars(''))
        }
        return res
    } catch (e) {
        console.log(e)
    }
}

export const fetchUpdateCarOil = (payload: FormData, carId: number) => async (dispatch: RootDispatch) => {
    try {
        const res = await api.updateOil(payload, carId)
        if (res.status === 200) {
            dispatch(fetchCars(''))
        }
        return res
    } catch (e) {
        console.log(e)
    }
}

export const fetchDeleteCarInsurance = (carId: number) => async (dispatch: RootDispatch) => {
    try {
        const res = await api.deleteInsurance(carId)
        if (res?.status === 204) {
            dispatch(fetchCars(''))
        }
        return res
    } catch (e) {
        console.log(e)
    }
}

export const fetchUpdateCarInsurance = (payload: FormData, carId: number) => async (dispatch: RootDispatch) => {
    try {
        const res = await api.updateInsurance(payload, carId)
        if (res.status === 200) {
            dispatch(fetchCars(''))
        }
        return res
    } catch (e) {
        console.log(e)
    }
}
export const fetchAddInsInfo = (payload: FormData) => async (dispatch: RootDispatch) => {
    try {
        const res = await api.addInsurance(payload)
        if (res?.status === 201) {
            dispatch(fetchCars(''))
        }
        return res
    } catch (e) {
        console.log(e)
    }
}

export const fetchEditCar = (data: FormData, carId: number) => async (dispatch: RootDispatch) => {
    try {
        const res = await api.editCar(data, carId)
        if (res.status === 200) {
            dispatch(fetchCars(''))
        }
        return res
    } catch (e) {
        console.log(e)
    }
}