import { createAsyncThunk } from "@reduxjs/toolkit";
import api from '../../api/blacklist'
import {RootDispatch} from "../reducers/RootReducer";

const SERVER_ERROR = 'Ошибка на стороне сервера'

export const fetchBlackListClients = createAsyncThunk(
    'blacklist/fetchAll',
    async (payload: any, thunkAPI) => {
        try {
            const res = await api.getBlackListClients(payload)
            return res.data
        } catch (e) {
            return thunkAPI.rejectWithValue(SERVER_ERROR)
        }
    }
)

export const fetchBlackListClientsWithFilter = createAsyncThunk(
    'black_list/fetchWithFilter',
    async (payload: any, thunkAPI) => {
        try {
            const res = await api.clientsFromBlackFilter(payload)
            return res.data
        } catch (e) {
            return thunkAPI.rejectWithValue(SERVER_ERROR)
        }
    }
)

export const fetchEditClientBlackList = (data: FormData, clientId: number) => async (dispatch: RootDispatch) => {
    try {
        const res = await api.editClient(data, clientId)
        if (res.status === 200) {
            dispatch(fetchBlackListClients(''))
        }
        return res
    } catch (e) {
        console.log(e)
    }
}
//
// export const fetchEditClientPhoto = (photoId: number, file: FormData) => async (dispatch: RootDispatch) => {
//     try {
//         return await api.editClientPhoto(photoId, file);
//     } catch (error) {
//         console.log(error)
//     }
// }
//
export const fetchCreateBlackListClient = (data: FormData) => async (dispatch: RootDispatch) => {
    try {
        return await api.createBlackListClient(data)
    } catch (e) {
        console.log(e)
    }
}

// export const fetchDeleteClient = (clientId: number) => async (dispatch: RootDispatch) => {
//     try {
//         return await api.deleteClient(clientId)
//     } catch (e) {
//         console.log(e)
//     }
// }

export const fetchBlockClient = (data: FormData) => async (dispatch: RootDispatch) => {
    try {
        return await api.blockClient(data)
    } catch (e) {
        console.log(e)
    }
}

export const fetchUnBlockClient = (id: number) => async (dispatch: RootDispatch) => {
    try {
        return await api.unblockClient(id)
    } catch (e) {
        console.log(e)
    }
}