import React, {useState} from 'react'
import {Button, Stack, TextField} from "@mui/material";
import {MUI_INPUTS_SIZE} from "../../const";
import {useTranslation} from "react-i18next";
import {fetchClients, fetchClientsWithFilter} from "../../redux/actions/clientsActions";
import {useAppDispatch} from "../../hooks/redux";
import * as timers from "timers";

const ClientsFilter = () => {

    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const [filter, setFilter] = useState("")

    const setFilterAndSearch = async (e:any) => {
        const value = e.target.value;
        setFilter(value);
        await searchByFilter(value);
    }

    const searchByFilter = async (value:string) => {
        !!value ? dispatch(fetchClientsWithFilter(value)) : dispatch(fetchClients(''))
    }

    return (
        <Stack display="flex" direction="row" justifyContent="center" alignItems="center" sx={{mt: 2}}>
            <TextField
                value={filter}
                onChange={setFilterAndSearch}
                variant="outlined"
                size={MUI_INPUTS_SIZE}
                label={`${t('passport')} | ${t('fullName')}`}
            />
            {/*<Button sx={{ml: 2}} onClick={searchByFilter} variant="outlined">*/}
            {/*    {t('search')}*/}
            {/*</Button>*/}
        </Stack>
    )
}

export default React.memo(ClientsFilter)