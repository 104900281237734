import axios from "axios";


export default {
    getCarRepair(payload: string){
        return axios.get(!!payload ? payload: "/api/report/car_repair/")
    },
    carsFromCarRepairFilter(params: any) {
        return axios.get(`/api/report/car_repair/`, {params});
  },
    createCarRepair(payload: FormData) {
        return axios.post("/api/report/car_repair/", payload);
    },
    editCarRepair(payload: FormData, entity_id: any) {
        return axios.patch(`/api/report/car_repair/${entity_id}/`, payload)
    },
    deleteCarRepair(carId: any) {
        return axios.delete(`/api/report/car_repair/${carId}/`)
    },
    fetchCarNums(payload: string){
        return axios.get(!!payload ? payload: "/api/report/car_nums/")
    },
    fetchDownloadExcel(params: any){
        return axios.get('/api/report/excel/', {params})
    }
}