import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { TextField, TextFieldProps } from '@mui/material'
import React from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Control, Controller, FieldPath } from 'react-hook-form'
import { MUI_INPUTS_SIZE } from '../../const'
import {ITypes} from '../../models'
import ruLocale from 'date-fns/locale/ru'
import i18next from "i18next";
import enLocal from 'date-fns/locale/en-US'
import uzLocal from 'date-fns/locale/uz'
import { fDashedDate, ObjectByString } from '../../utils/utils';
import {useTranslation} from "react-i18next";

type Props = TextFieldProps & {
    label: string
    control: Control<any>
    name: FieldPath<ITypes>
    disableFuture?: boolean,
    errors?: {}
    minDate?: any
}

const locales = {
    "en": enLocal,
    "ru": ruLocale,
    "uz": uzLocal
}

const CustomDatePicker = ({ label, errors, control, name, minDate, disableFuture, ...props }: Props) => {

    const {t} = useTranslation()

    return (
        <Controller
            control={control}
            name={name}
            rules={{ required: true }}
            render={({ field }) => (
                //@ts-ignore
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locales["ru"]}>
                    <DatePicker
                        {...field}
                        label={label}
                        disableFuture={disableFuture}
                        minDate={minDate}
                        onChange={(value) => {
                            let dashed = fDashedDate(value);
                            return field.onChange(dashed);
                        }}
                        renderInput={(params: any) => (
                            <TextField
                                variant="outlined"
                                error={Boolean(ObjectByString(errors, field.name))}
                                helperText={
                                    ObjectByString(errors, field.name)
                                        ? t('fillThisField')
                                        : ""
                                }
                                size={MUI_INPUTS_SIZE}
                                inputProps={{
                                    ...params.inputProps,
                                    placeholder: "__-__-____",
                                }}
                                {...params}
                                {...props}
                            />
                        )}
                    />
                </LocalizationProvider>
            )}
        />
    )
}

export default React.memo(CustomDatePicker)