import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './locales'
import { BrowserRouter } from 'react-router-dom';
import './styles/index.scss'
import { Loader } from './components';
import { Provider } from 'react-redux';
import { setupStore } from './redux/reducers/RootReducer';
import axios from 'axios'

const store = setupStore()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

axios.defaults.baseURL = process.env.REACT_APP_DEVELOPMENT_URL || ""

root.render(
  <Suspense fallback={<Loader />}>
    <Provider store={store}>
      <BrowserRouter>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </BrowserRouter>
    </Provider>
  </Suspense>
);
