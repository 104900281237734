import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import carsReducer from "./carsReducer";
import clientsReducer from "./clientReducer"
import ordersReducer from "./ordersReducer"
import appReducer from "./appReducer";
import blacklistReducer from "./blacklistReducer";
import carrepairReducer from "./carrepairReducer";

export const rootReducer = combineReducers({
  carsReducer,
  clientsReducer,
  ordersReducer,
  appReducer,
  blacklistReducer,
  carrepairReducer
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(),
  });
};

export type RootReducer = ReturnType<typeof rootReducer>;
export type RootStore = ReturnType<typeof setupStore>;
export type RootDispatch = RootStore["dispatch"];
