import { Navigate, useRoutes } from "react-router-dom";
import {CarsPage, Entry, Login, NotFound, CarAdd, ClientsPage, OrdersPage, CreateOrderPage, ClientAdd,
    BlackList, BlackListAdd, CarRepair} from "./pages";
import MainLayout from './components/layout'

export default function Router({ isAuth }: { isAuth: boolean }) {

    return useRoutes([
        {
            path: '/app',
            element: isAuth ? <MainLayout /> : <Navigate to="/login" />,
            children: [
                { path: '', element: <Entry /> },
                { path: 'carslist', element: <CarsPage /> },
                { path: 'carslist/:id', element: <CarsPage /> },
                { path: 'clientslist', element: <ClientsPage /> },
                { path: 'clientslist/:id', element: <ClientsPage /> },
                { path: 'orders', element: <OrdersPage /> },
                { path: 'create_order', element: <CreateOrderPage /> },
                { path: 'addclient', element: <ClientAdd /> },
                { path: 'car_add', element: <CarAdd /> },
                { path: 'black_list', element: <BlackList />},
                { path: 'black_list/:id', element: <BlackList />},
                { path: 'black_list_add', element: <BlackListAdd/>},
                { path: 'car_repair', element: <CarRepair/>}

            ]
        },
        {
            path: '/',
            element: isAuth ? <Navigate to="/app/carslist" /> : <Login />,
            children: [
                { path: 'login', element: <Login /> },
                { path: '404', element: <NotFound /> },
                { path: '/', element: <Navigate to="/app/carslist" /> },
                { path: '*', element: <Navigate to="/404" /> },
            ]
        },
        { path: '*', element: <Navigate to="/404" replace /> }
    ]);
}
