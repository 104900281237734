import React, { ChangeEvent } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';


type FilterBoxProps = {
  truckNames: string[];
  onInputChange: (event: ChangeEvent<{}>, selected: string[]) => void;
  selectedGroups: string[];
  label: string,
  placeholder: string
};

const FilterBox = (props: FilterBoxProps) => {
  return (
      <Autocomplete
      multiple={true}
      value={props.selectedGroups}
      id="combo-box"
      options={props.truckNames}
      getOptionLabel={option => option}
      filterSelectedOptions
      style={{ width: 400 }}
      onChange={props.onInputChange}
      renderInput={params => (
        <TextField
          {...params}
          label={props.label}
          variant="outlined"
          placeholder={props.placeholder}
        />
      )}
    />
  );
}

export default React.memo(FilterBox)