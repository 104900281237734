import * as yup from 'yup'

export interface IClient {
    client_id?: number
    passport_series: string
    firstname: string
    lastname: string
    second_name: string
    date_of_issue: string | null
    issued: string
    address: string
    phone: string
    photo?: any
    in_black_list?: boolean
    status?: "vacant" | "occupied"
    citizenship: string
    date_of_birth: string | null
}

export const clientModel: IClient = {
    passport_series: "",
    firstname: "",
    lastname: "",
    second_name: "",
    date_of_issue: null,
    issued: "",
    address: "",
    phone: "998",
    citizenship: "Uzbekistan",
    date_of_birth: null
}

export const clientSchema = yup.object({
    passport_series: yup.string().min(5).max(20).required(),
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    second_name: yup.string().required(),
    date_of_issue: yup.string().nullable().required(),
    issued: yup.string().required(),
    address: yup.string().required(),
    phone: yup.string().min(12).max(17).required(),
    citizenship: yup.string().required(),
    date_of_birth: yup.string().nullable().required()
}).required()