import React, { ReactNode } from 'react'
import { TextField, TextFieldProps } from '@mui/material'
import { MUI_INPUTS_SIZE } from '../../const';
import { Control, Controller, FieldPath } from 'react-hook-form';
import { ITypes } from '../../models';
import { ObjectByString } from '../../utils/utils';
import {useTranslation} from "react-i18next";

type Props = TextFieldProps & {
    label?: ReactNode;
    control: Control<any>
    name: FieldPath<ITypes>
    rules?: {},
    errors?: {}
}

const Input = ({ label, name, rules, errors, control, ...props }: Props) => {

    const {t} = useTranslation()

    return (
        <Controller
            name={name}
            rules={rules}
            control={control}
            render={({ field }) => (
                <TextField
                    size={MUI_INPUTS_SIZE}
                    label={label}
                    {...field}
                    {...props}
                    InputLabelProps={{ shrink: Boolean(field.value) || field.value === 0 }}
                    error={Boolean(Object.keys(errors || {}).length) && Boolean(ObjectByString(errors, field.name))}
                    helperText={
                        Boolean(Object.keys(errors || {}).length) &&
                            ObjectByString(errors, field.name)
                            ? t('fillThisField')
                            : ""
                    }
                />
            )}
        />
    )
}

export default React.memo(Input)