import React, {useMemo} from 'react'
import {ICar, IOil, oilModel, oilSchema} from "../../models";
import {Box, Button, ButtonGroup, Stack, Typography} from "@mui/material";
import {useToggle} from "../../hooks/use-toggle";
import Add from '@mui/icons-material/Add'
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import Input from "../input";
import DatePicker from "../datepicker/DatePicker";
import {copyObject, fDate} from "../../utils/utils";
import {useAppDispatch} from "../../hooks/redux";
import {fetchAddCarOil, fetchDeleteCarOil, fetchUpdateCarOil} from "../../redux/actions/carsActions";
import {appSlice} from "../../redux/reducers/appReducer";
import { useFormState } from '../../hooks/use-form';
import {yupResolver} from '@hookform/resolvers/yup'
import i18next from 'i18next';

const OilInfo = (props: ICar) => {

    const {oil, car_id} = props
    const {t} = useTranslation()
    const dispatch = useAppDispatch()

    const {handleSubmit, control, formState: {errors, submitCount}} = useForm<IOil>({
        defaultValues: !!oil ? copyObject(oil) : copyObject(oilModel),
        resolver: yupResolver(oilSchema)
    })

    const service = useFormState(errors, submitCount)

    const updateOil = (data: IOil) => {
        sendOilInfo(data, true)
    }

    const createOilInfo = (data: IOil) => {
        sendOilInfo(data, false)
    }

    const sendOilInfo = async (data: IOil, upd: boolean) => {
        data.car_id = car_id as number
        // @ts-ignore
        upd && delete data.car_id
        const fd = new FormData()

        for (const [key, value] of Object.entries(data)) {
            fd.append(key, value)
        }

        const res = upd ? await dispatch(fetchUpdateCarOil(fd, car_id as number)) : await dispatch(fetchAddCarOil(fd))
        if ([200, 201].includes(res?.status as number)) {
            dispatch(appSlice.actions.toggleSystemAlert({
                severity: 'success',
                show: true,
                autoHide: true,
                text: upd ? t('oilUpdSuccess') : t('oilAddSuccess')
            }))
        }
    }

    const deleteOilInfo = async () => {
        const conf = window.confirm(t('delete') + '?')
        if (conf) {
            const res = await dispatch(fetchDeleteCarOil(car_id as number))
            if (res?.status === 204) {
                dispatch(appSlice.actions.toggleSystemAlert({
                    severity: 'info',
                    show: true,
                    autoHide: true,
                    text: t('oilDelete')
                }))
            }
        }
    }

    const edit = useToggle(false)

    return (
        <Box className="flex jcc col aic">
            {
                edit.state ? (
                    <form onSubmit={handleSubmit(createOilInfo)}>
                        <Stack direction={"column"} sx={{p: 2}}>
                            <Input
                                label={t('name')}
                                name="name"
                                control={control}
                                errors={errors}
                                fullWidth
                                sx={{mt: 2}}
                            />
                            <Input
                                label={t('mileage')}
                                name="mileage"
                                control={control}
                                errors={errors}
                                type={"number"}
                                fullWidth
                                sx={{mt: 2}}
                            />
                            <DatePicker
                                label={t('date')}
                                control={control}
                                errors={errors}
                                //@ts-ignore
                                name={"date"}
                                fullWidth
                                sx={{mt: 2}}
                            />
                            <Input
                                label={t('oil_mileage')}
                                name="oil_mileage"
                                control={control}
                                errors={errors}
                                type={"number"}
                                fullWidth
                                sx={{mt: 2}}
                            />
                            <Input
                                label={t('miles_left')}
                                name="miles_left"
                                control={control}
                                errors={errors}
                                type={"number"}
                                fullWidth
                                sx={{mt: 2}}
                            />
                            <ButtonGroup variant="outlined" sx={{mt: 2}}>
                                <Button color="error" onClick={() => deleteOilInfo()}>
                                    {t('delete')}
                                </Button>
                                {
                                    !!oil ? (
                                        <Button onClick={handleSubmit(updateOil)}>
                                            {t('save')}
                                        </Button>
                                    ) : (
                                        <Button type={"submit"}>
                                            {t('save')}
                                        </Button>
                                    )
                                }
                            </ButtonGroup>
                        </Stack>
                    </form>
                ) : (
                    !!oil ? (
                        <>
                            <Box className="flex row jcc aic">
                                <Typography variant="h6" component="h6">
                                    {t('name')}: &nbsp;
                                </Typography>
                                <Typography component={"h6"} variant="body1">
                                    {oil.name}
                                </Typography>
                            </Box>
                            <Box className="flex row jcsb aic">
                                <Typography variant="h6" component="h6">
                                    {t('mileage')}: &nbsp;
                                </Typography>
                                <Typography component={"h6"} variant="body1">
                                    {oil.mileage} km
                                </Typography>
                            </Box>
                            <Box className="flex jcsb aic">
                                <Typography variant="h6" component="h6">
                                    {t('date')}: &nbsp;
                                </Typography>
                                <Typography component={"h6"} variant="body1">
                                    {fDate(oil.date, i18next.language)}
                                </Typography>
                            </Box>
                            <Box className="flex jcsb aic">
                                <Typography variant="h6" component="h6">
                                    {t('miles_left')}: &nbsp;
                                </Typography>
                                <Typography component={"h6"} variant="body1">
                                    {oil.miles_left} km
                                </Typography>
                            </Box>
                            <Box className="flex jcsb aic">
                                <Typography variant="h6" component="h6">
                                    {t('oil_mileage')}: &nbsp;
                                </Typography>
                                <Typography component={"h6"} variant="body1">
                                    {oil.oil_mileage} km
                                </Typography>
                            </Box>
                            <Box className="flex jcsb aic">
                                <ButtonGroup variant="outlined">
                                    <Button color="error" onClick={() => deleteOilInfo()}>
                                        {t('delete')}
                                    </Button>
                                    <Button onClick={() => edit.on()}>
                                        {t('edit')}
                                    </Button>
                                </ButtonGroup>
                            </Box>
                        </>
                    ) : (
                        <Button onClick={() => edit.toggle()}>
                            <Add/> {t('addOilInfo')}
                        </Button>
                    )
                )

            }
        </Box>
    )
}

export default React.memo(OilInfo)