import React, { useState } from 'react'
import { Dialog, Button, DialogTitle, DialogContent, Typography } from '@mui/material'
import { useToggle } from '../../hooks/use-toggle'
import { useAppDispatch } from '../../hooks/redux'
import { useTranslation } from 'react-i18next'
import { fetchEditOrder } from '../../redux/actions/ordersActions'
import {useForm} from 'react-hook-form'
import Input from '../input'
import { appSlice } from '../../redux/reducers/appReducer'

interface IProps {
    orderId: number
    total_cost: number
    notes: string
}

interface IForm {
    mileage: string
    status: string
    total_cost: number
    notes: string
}

const OrderCancelModal = ({orderId, total_cost, notes}: IProps) => {

    const modal = useToggle(false)
    const dispatch = useAppDispatch()
    const {t} = useTranslation()

    const {control, handleSubmit} = useForm<IForm>({
        defaultValues: {mileage: "0", status: "finished", total_cost: total_cost, notes: notes ? notes : ''}
    })

    const stopOrder = async (data: IForm) => {
        const ans = window.confirm(t('stop') + '?')

        const fd = new FormData()
        
        for (const [key, value] of Object.entries(data)) {fd.append(key, value)}
        
        if (ans) {
            const res = await dispatch(fetchEditOrder(fd, orderId))
            console.log(res)
            if (res?.status === 200) {
                dispatch(appSlice.actions.toggleSystemAlert({
                    severity: "info",
                    show: true,
                    autoHide: true,
                    text: t('orderStop')
                }))
                modal.off()
            }
        }
    }



    return (
        <>
            <Dialog open={modal.state} onClose={() => modal.off()}>
                <DialogTitle>
                    <Typography component="h5">
                        {t('orderMileage')}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit(stopOrder)}>
                        <Input
                            control={control}
                            //@ts-ignore
                            name="mileage"
                            type="number"
                            label={t('orderMileage')}
                            sx={{mt: 2}}
                        />
                        <Input
                            control={control}
                            //@ts-ignore
                            name="total_cost"
                            type="number"
                            label={t('order_total_cost')}
                            sx={{mt: 2}}
                        />
                        <Input
                            control={control}
                            //@ts-ignore
                            name="notes"
                            type="string"
                            label={t('order_notes')}
                            sx={{mt: 2}}
                        />
                        <Button type="submit" sx={{ml: 2, mt: 2}}>
                            {t('send')}
                        </Button>
                    </form>
                </DialogContent>
            </Dialog>
            <Button onClick={() => modal.on()} color="error">
                {t('stop')}
            </Button>
        </>
    )
}

export default React.memo(OrderCancelModal)