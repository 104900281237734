import axios from "axios"
import { ILogin } from "../models"

export default {
    login(data: ILogin) {
        return axios.post('/api/login/', data)
    },
    logout() {
        return axios.get('/api/logout/')
    }
}