import * as React from 'react';
import { useMemo, useState } from 'react';
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  MRT_ColumnFiltersState,
  // createRow,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
  useMaterialReactTable,
} from 'material-react-table';
import {
  Box,
  Button, ButtonGroup,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../hooks/redux'
import {fetchRepairedCars,
    fetchDeleteCarRepair,
    fetchEditCarRepair,
    fetchRepairedCarsWithFilter,
    fetchCarNums,
    fetchCreateCarRepair} from '../redux/actions/carrepairAction'
import {ICarRepairModel, ICarRepair} from "../models";

import {appSlice} from "../redux/reducers/appReducer";
import { PieChart } from '@mui/x-charts/PieChart';
import {PieItemId} from "@mui/x-charts/models/seriesType/pie";
import DownloadAccordion from '../components/carrepair/accordion'
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import ruLocale from "date-fns/locale/ru";
import { LocalizationProvider } from '@mui/x-date-pickers'
import { MRT_Localization_RU } from 'material-react-table/locales/ru';

const CarRepair = () => {

  const { results: CarRepairList, next: nextPage, previous: prevPage } = useAppSelector(state => state.carrepairReducer)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [CarNums, SetCarNums] = useState([])
  const { t } = useTranslation()

  // useEffect(() => {
  //
  //     dispatch(fetchRepairedCars(''))
  // }, [])


  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    [],
  );
  // const [globalFilter, setGlobalFilter] = useState('');
  useEffect(() => {
    const fetchData = async () => {

        if (columnFilters.length > 0){
            const id = columnFilters[0].id
            const value = columnFilters[0].value
            dispatch(fetchRepairedCarsWithFilter({car: value}))
        }
        else {
            dispatch(fetchRepairedCars(''))
        }
        const data = await dispatch(fetchCarNums(''))
        SetCarNums(data.payload.results)
    }
    fetchData()
  }, [columnFilters])

  const handlePageChange = useCallback((type: "next" | "previous") => {
      if (type === "next") {
          dispatch(fetchRepairedCars(nextPage))
      } else {
          dispatch(fetchRepairedCars(prevPage))
      }
  }, [nextPage, prevPage])


    interface KeyValue {
    key: string;
    value: string;
    }

    function findKeyByValue(arr: KeyValue[], val: string): string {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].value === val) {
            return arr[i].key;
        }
    }
    return "0"
    }
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});

  const columns = useMemo<MRT_ColumnDef<ICarRepair>[]>(
    () => [
      // {
      //   accessorKey: 'id',
      //   header: 'Id',
      //   enableEditing: false,
      //   size: 80,
      //   enableColumnFilter: false
      // },
      {
        accessorKey: 'model',
        header: t('model'),
        enableEditing: false,
        size: 100,
        enableColumnFilter: false,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.model,
          helperText: validationErrors?.model,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              model: undefined,
            }),
        },
      },
      {
        accessorKey: 'car_num',
        header: t('number'),
        size: 100,
        // enableEditing: false,
        editVariant: 'select',
        editSelectOptions: CarNums,

        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.car_num,
          helperText: validationErrors?.car_num,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              car_num: undefined,
            }),
        },
      },
        {
        accessorKey: 'date',
        header: t('date'),
        size: 100,
        enableColumnFilter: false,
        // filterVariant: 'date',
        //  Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(), //render Date as a string
        // filterVariant: 'date-range',
        // Cell: ({ cell }) => cell.getValue<Date>().toLocaleDateString(),
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.date,
          helperText: validationErrors?.date,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              date: undefined,
            }),
        },
      },
        {
        accessorKey: 'reason',
        header: t('reason'),
        size: 350,
        enableColumnFilter: false,

        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.reason,
          helperText: validationErrors?.reason,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              car_num: undefined,
            }),
        },
      },
        {
        accessorKey: 'cost',
        header: t('car_repair_cost'),
        enableColumnFilter: false,

        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.cost,
          helperText: validationErrors?.cost,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              car_num: undefined,
            }),
        },
      }
    ],
    [validationErrors],
  );

  //CREATE action
  const handleCreateUser: MRT_TableOptions<ICarRepair>['onCreatingRowSave'] = async ({
    values,
    table,
  }) => {
      const formData = new FormData()
      for (let [key, value] of Object.entries(values)) {
          if (key === "id" || key === "model"){
              continue
          }
          else if (key === "car_num") {
              const car_id = findKeyByValue(CarNums, value)
              formData.append("car_id", car_id);
          }
          else{
            formData.append(key, value)
          }
      }
      const res = await dispatch(fetchCreateCarRepair(formData))
        if (res?.status === 201) {
            dispatch(appSlice.actions.toggleSystemAlert({
                text: t('carEditSuccess'),
                severity: 'success',
                show: true,
                autoHide: true
            }))
             dispatch(fetchRepairedCars(''))
        }
        else {
            dispatch(appSlice.actions.toggleSystemAlert({
                text: 'Error while adding car repair entity',
                severity: 'error',
                show: true,
                autoHide: true
            }))
        }
    table.setCreatingRow(null); //exit creating mode
  };

  //UPDATE action
  const handleSaveUser: MRT_TableOptions<ICarRepair>['onEditingRowSave'] = async ({
    values,
    table,
  }) => {
       const formData = new FormData()
        for (const [key, value] of Object.entries(values)) {
            if (key === "id"){
                continue
            }
            formData.append(key, value)
        }
        const res = await dispatch(fetchEditCarRepair(formData, values.id))
        if (res?.status === 200) {
            dispatch(appSlice.actions.toggleSystemAlert({
                text: t('carEditSuccess'),
                severity: 'success',
                show: true,
                autoHide: true
            }))
        }
    table.setEditingRow(null); //exit editing mode
  };

  const DeleteEntity = async (row: any) => {
        const res = await dispatch(fetchDeleteCarRepair(row.id))
        if (res?.status === 204) {
            dispatch(fetchRepairedCars(''))
        }
    }

    type PieValueType = {
        id: PieItemId;
        value: number;
        label?: string;
        color?: string;
    };
  const PrepareDataForPie = (data: any) => {
      const new_data:PieValueType[] = []
      for (const inner_data of data) {
          const typed_data: ICarRepair = inner_data
            new_data.push({
                id: parseInt(typed_data.id, 10),
                value: parseFloat(typed_data.cost),
                label: typed_data.car_num
            })
        }
      return new_data
  }

  const table = useMaterialReactTable({
    columns,
    data: CarRepairList,
    localization: MRT_Localization_RU,
    manualFiltering: true,
    manualPagination: true,
    onColumnFiltersChange: setColumnFilters,
    state: {columnFilters},
    createDisplayMode: 'row', //default ('row', and 'custom' are also available)
    editDisplayMode: 'row', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    enableDensityToggle: false,
    enablePagination: false,
    // enableStickyFooter: true,
      enableTableFooter:false,
    // enableFilters: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    getRowId: (row) => row.id,
    initialState: { showColumnFilters: true },
    muiToolbarAlertBannerProps: ''
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: '400px',
        maxHeight: '400px'
      },
    },
    muiTableBodyCellProps: {
      sx: {
         // padding: '0px !important',
        paddingBottom: '0px !important',
        paddingTop: '0px !important'
      }
    },
    muiTableBodyRowProps: {
      sx: {
        Height: '10px'
      }
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateUser,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveUser,
    //optionally customize modal content
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Create New User</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    //optionally customize modal content
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Edit User</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => DeleteEntity(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (

        <Tooltip title="Create">
          <IconButton
              color="primary"
              onClick={() => {
          table.setCreatingRow(true); //simplest way to open the create row modal with no default values
          //or you can pass in a row object to set default values with the `createRow` helper function
          // table.setCreatingRow(
          //   createRow(table, {
          //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
          //   }),
          // );
        }}>
            <AddIcon  />
          </IconButton>
        </Tooltip>
    ),
      renderBottomToolbarCustomActions: ({ table }) => (
                    <ButtonGroup variant="outlined" sx={{ position: "right" }}>
                        <Button
                            onClick={() => handlePageChange("previous")}
                            disabled={!Boolean(prevPage)}
                        >
                            {t('previous')}
                        </Button>
                        <Button
                            onClick={() => handlePageChange('next')}
                            disabled={!Boolean(nextPage)}
                        >
                            {t('next')}
                        </Button>
                    </ButtonGroup>
      )
  });

  return (
    <Box sx={{ p: { xs: 1, md: 0 }, maxWidth: '1400px', margin: '0 auto' }}>
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
    <DownloadAccordion />

    <MaterialReactTable table={table} />

      <Box sx={{paddingTop: '10px'}}>
          <PieChart
              series={[
                {
                  data: PrepareDataForPie(CarRepairList),
                  innerRadius: 29,
                  outerRadius: 143,
                  paddingAngle: 1,
                  cornerRadius: 5,
                },
              ]}
              // width={600}
              height={400}
              slotProps={{
                legend: {
                  direction: 'row',
                  position: { vertical: 'top', horizontal: 'middle' },
                  padding: 0,
                }
              }}

            />
      </Box>
    </LocalizationProvider>
    </Box>
      )
};

export default React.memo(CarRepair)
