import React, {useState} from 'react'
import Close from '@mui/icons-material/Close'
import Edit from '@mui/icons-material/Edit'
import {
    Button,
    CardMedia, Dialog, DialogContent, DialogTitle, Divider,
    Grid, IconButton, Stack, Tooltip,
    Typography, useMediaQuery, useTheme
} from "@mui/material";
import { carModel, ICar } from "../../models";
import { useParams } from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import { useNav } from "../../hooks/use-nav";
import { useToggle } from "../../hooks/use-toggle";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { copyObject, fCurrency } from "../../utils/utils";
import { Input } from "../index";
import UploadFile from "@mui/icons-material/UploadFile";
import noImg from '../../assets/no-car.webp'
import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt'
import {fetchCars, fetchDeleteCar, fetchEditCar} from "../../redux/actions/carsActions";
import {appSlice} from "../../redux/reducers/appReducer";
import Delete from '@mui/icons-material/Delete'
import OilBarrel from '@mui/icons-material/OilBarrel'
import OilInfo from "./OilInfo";
import Article from '@mui/icons-material/Article'
import Insurance from "./Insurance";
import { useFormState } from '../../hooks/use-form';

interface Props {
    id: string
    modal: {
        state: boolean
        on: () => void
        off: () => void
        toggle: () => void
    }
}

const CarModal = ({ modal }: Props) => {

    const { id } = useParams()
    const { goBack } = useNav()
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const theme = useTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))

    const edit = useToggle(false)
    const oil = useToggle(false)
    const insurance = useToggle(false)

    const [carImg, setCarImg] = useState<any>(null)

    const carsList = useAppSelector(state => state.carsReducer.cars)
    const foundCar = carsList.find(car => `${car.car_id?.toString()}` === id) || copyObject(carModel) as ICar

    const { brand, model, photo, year_of_issue, toning, cost, rent_cost, mileage } = foundCar

    const { control, handleSubmit, formState: {errors, submitCount} } = useForm<ICar>({
        defaultValues: JSON.parse(JSON.stringify(foundCar))
    })

    const service = useFormState(errors, submitCount)

    const handleCarImg = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setCarImg(event.target.files[0])
        } else {
            setCarImg(null)
        }
    }

    const close = () => {
        modal.off()
        edit.off()
        goBack()
    }

    const editCar = async (data: ICar) => {
        const formData = new FormData()
        delete data.photo
        for (const [key, value] of Object.entries(data)) {
            formData.append(key, value)
        }
        carImg && formData.append("photo", carImg)
        const res = await dispatch(fetchEditCar(formData, foundCar.car_id as number))
        if (res?.status === 200) {
            dispatch(appSlice.actions.toggleSystemAlert({
                text: t('carEditSuccess'),
                severity: 'success',
                show: true,
                autoHide: true
            }))
            close()
        }
    }

    const deleteCar = async () => {
        const ans = window.confirm(t('delete') + '?')

        if (ans) {
            const res = await dispatch(fetchDeleteCar(foundCar.car_id as number))
            if (res?.status === 204) {
                dispatch(fetchCars(''))
                close()
            }
        }
    }

    const toggle = (param: "edit" | "oil" | "insurance") => {
        if (param === "edit") {
            edit.toggle()
            oil.off()
            insurance.off()
        } else if (param === "oil") {
            oil.toggle()
            edit.off()
            insurance.off()
        } else if (param === "insurance") {
            insurance.on()
            edit.off()
            oil.off()
        }
    }

    return (
        <Dialog sx={{ transition: '0.3s' }} open={modal.state} onClose={() => close()} maxWidth={"md"}>
            <Stack direction={sm ? 'column' : 'row'} sx={{ p: 1, transition: '0.3s' }}>
                <Stack display="flex" direction={sm ? 'row' : 'column'} justifyContent={sm ? 'center' : 'start'}>
                    <Tooltip
                        title={"Закрыть"}
                        arrow
                        disableInteractive
                        placement={"left"}
                    >
                        <IconButton color={"error"} onClick={() => close()}>
                            <Close />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title={"Редактировать"}
                        arrow
                        disableInteractive
                        placement={"left"}
                    >
                        <IconButton color={"info"} onClick={() => toggle("edit")}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title={t('oil')}
                        arrow
                        disableInteractive
                        placement={"left"}
                    >
                        <IconButton color={"primary"} onClick={() => toggle("oil")}>
                            <OilBarrel />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title={t('ins')}
                        arrow
                        disableInteractive
                        placement={"left"}
                    >
                        <IconButton color={"primary"} onClick={() => toggle("insurance")}>
                            <Article />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title={t('delete')}
                        arrow
                        disableInteractive
                        placement={"left"}
                    >
                        <IconButton color={"error"} onClick={() => deleteCar()}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </Stack>
                <Stack direction={{ sm: 'column', md: edit.state ? 'column-reverse' : 'row' }}>
                    {
                        edit.state && (
                            <DialogContent>
                                <form onSubmit={handleSubmit(editCar)}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Input
                                                label={t('brand')}
                                                fullWidth
                                                name="brand"
                                                control={control}
                                                errors={errors}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Input
                                                label={t('model')}
                                                fullWidth
                                                control={control}
                                                errors={errors}
                                                name="model"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Input
                                                label={t('number')}
                                                fullWidth
                                                control={control}
                                                errors={errors}
                                                name="car_num"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Input
                                                label={t('toning')}
                                                fullWidth
                                                control={control}
                                                errors={errors}
                                                name="toning"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Input
                                                label={t('yearOfIssue')}
                                                fullWidth
                                                control={control}
                                                errors={errors}
                                                name="year_of_issue"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Input
                                                label={t('color')}
                                                fullWidth
                                                control={control}
                                                errors={errors}
                                                name="color"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Input
                                                label={t('engineNum')}
                                                fullWidth
                                                control={control}
                                                errors={errors}
                                                name="engine_num"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Input
                                                label={t('bodyNum')}
                                                fullWidth
                                                control={control}
                                                errors={errors}
                                                name="body_num"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Input
                                                label={t('techPassport')}
                                                fullWidth
                                                control={control}
                                                errors={errors}
                                                name="tech_passport"
                                            />
                                        </Grid>
                                         <Grid item xs={12} sm={6} md={4}>
                                            <Input
                                                label={t('mileage')}
                                                fullWidth
                                                control={control}
                                                errors={errors}
                                                name="mileage"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Input
                                                label={t('totalCost')}
                                                fullWidth
                                                control={control}
                                                errors={errors}
                                                name="cost"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Input
                                                label={t('price/day')}
                                                fullWidth
                                                control={control}
                                                errors={errors}
                                                name="rent_cost"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Input
                                                label={t('accumulator')}
                                                fullWidth
                                                control={control}
                                                errors={errors}
                                                name="battery"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Input
                                                label={t('tire')}
                                                fullWidth
                                                control={control}
                                                errors={errors}
                                                name="wheels_model"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Input
                                                label={t('spareWheel')}
                                                fullWidth
                                                control={control}
                                                errors={errors}
                                                name="spare_wheel"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={4}>
                                            <Tooltip title={"Выберите фотографию для машины"} arrow disableInteractive placement={"left"}>
                                                <>
                                                    <IconButton sx={{ mt: 2 }} color="primary" aria-label="upload image" component="label">
                                                        <input hidden accept="image/*" type="file" onChange={handleCarImg} />
                                                        <UploadFile />
                                                    </IconButton>
                                                    {
                                                        carImg && (
                                                            <Typography>
                                                                {carImg.name}
                                                            </Typography>
                                                        )
                                                    }
                                                </>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Button sx={{ mt: 2 }} variant={"outlined"} color={"success"} type={"submit"}>
                                        {t('save')}
                                    </Button>
                                </form>
                            </DialogContent>
                        )
                    }
                    {
                        oil.state && (
                            <DialogTitle>
                                <OilInfo {...foundCar} />
                            </DialogTitle>
                        )
                    }
                    {
                        insurance.state && (
                            <DialogTitle>
                                <Insurance {...foundCar} />
                            </DialogTitle>
                        )
                    }
                    {
                        (!oil.state && !edit.state && !insurance.state) && (
                            <DialogTitle>
                                <Typography variant={"h5"} component={"div"}>
                                    {brand} {model}
                                </Typography>
                                <Divider sx={{ mt: 2 }} />
                                <Typography variant={"h6"} component={"div"} sx={{ mt: 2 }}>
                                    {t('yearOfIssue')}: {year_of_issue}
                                </Typography>
                                <Typography variant={"h6"} component={"div"}>
                                    {t('toning')}: {toning}
                                </Typography>
                                <Typography variant={"h6"} component={"div"}>
                                    {t('totalCost')}: {fCurrency(cost)}
                                </Typography>
                                 <Typography variant={"h6"} component={"div"}>
                                    {t('mileage')}: {mileage} км
                                </Typography>
                                <Typography variant={"body1"} component={"div"}>
                                    {fCurrency(rent_cost)} {t('price/day')}
                                </Typography>
                            </DialogTitle>
                        )
                    }
                    <DialogContent className={`flex jcc aic ${sm ? 'col' : 'row'}`}>
                        <CardMedia
                            component={"img"}
                            sx={{ height: 200, width: carImg ? sm ? 250 : 300 : sm ? 250 : 500 }}
                            image={!!photo ? photo : noImg}
                        />
                        {
                            carImg && (
                                <>
                                    <ArrowRightAlt  sx={{width: '4rem', transform: sm ? 'rotate(90deg)' : 'rotate(0)', fontSize: 50}}/>
                                    <CardMedia
                                        component={"img"}
                                        sx={{ height: 200, width: sm ? 250 : 300 }}
                                        image={URL.createObjectURL(carImg)}
                                    />
                                </>
                            )
                        }
                    </DialogContent>
                </Stack>
            </Stack>
        </Dialog>
    )
}

export default React.memo(CarModal)