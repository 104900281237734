import { Button, Card } from '@mui/material'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { Input } from '../components'
import { useAuth } from '../hooks/use-auth'
import { ILogin, login, loginSchema } from '../models'
import { loginUser } from '../redux/actions/axiosReq'
import { useFormState } from '../hooks/use-form'
import { copyObject } from '../utils/utils'
import {yupResolver} from '@hookform/resolvers/yup'
import {useAppDispatch} from "../hooks/redux";
import {appSlice} from "../redux/reducers/appReducer";
import { useTranslation } from 'react-i18next'

const Login = () => {

    const { control, handleSubmit, formState: {errors, submitCount} } = useForm<ILogin>({ 
        defaultValues: copyObject(login),
        resolver: yupResolver(loginSchema)
    })
    const {t} = useTranslation()
    const auth = useAuth()
    const dispatch = useAppDispatch()

    const service = useFormState(errors, submitCount);

    const submit = useCallback(async (data: ILogin) => {
        const res = await loginUser(data)
        if ([200, 201].includes(res?.status as number)) {
            auth.login(res?.data.token)
            localStorage.setItem('user_type', res?.data.user_type)
            dispatch(appSlice.actions.toggleAuth(!!res?.data.token))
        }
    }, [])

    return (
        <div className="flex jcc aic h-full">
            <Card sx={{ p: 10, width: 'auto' }}>
                <h1>{t('welcome')}!</h1>
                <form onSubmit={handleSubmit(submit)} className="flex jcc aic col mt-2">
                    <Input label="Login" name="username" control={control} errors={errors} />
                    <Input label="Password" name="password" control={control} errors={errors} type="password" sx={{ mt: 2 }} />
                    <Button type="submit" variant="outlined" sx={{mt: 2}}>Send</Button>
                </form>
            </Card>
        </div>
    )
}

export default React.memo(Login)