import auth from "../../api/auth"
import { ILogin } from "../../models"

export const loginUser = async (data: ILogin) => {
    try {
        return await auth.login(data)
    } catch (e) {}
}

export const logoutUser = async () => {
    try {
        return await auth.logout()
    } catch (e) {}
}