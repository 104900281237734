import React from 'react'
import { ICar } from '../../models'
import { fCurrency } from "../../utils/utils";
import { Box, Card, CardContent, CardMedia, Stack, Typography } from "@mui/material";
import noImg from '../../assets/no-car.webp'
import { useTranslation } from "react-i18next";

interface IProps extends ICar {
    small?: boolean
    isSelected?: boolean
}

const CarCard = (props: IProps) => {

    const { t } = useTranslation()
    const { photo, brand, model, rent_cost, small, isSelected, status, car_num } = props

    return (
        <Card sx={{ borderRadius: 5, cursor: 'pointer', border: `${isSelected ? '2px' : '1px'} solid ${isSelected ? '#f00' : '#000'}`, display: small ? 'flex' : 'block' }}>
            <CardMedia
                component={"img"}
                height={small ? "177px" : "140px"}
                sx={{ maxWidth: small ? '150px' : 'auto' }}
                image={!!photo ? photo : noImg}
            />
            <CardContent  sx={{"paddingBottom":"12px !important"}}>
                <Typography variant={small ? "body1" : "h5"} component={"div"}>
                    {brand} {model}
                </Typography>
                <Typography variant={"body1"}>
                    {t('number')} {car_num}
                </Typography>
                {
                    !small && (
                        <Typography variant={"body1"} color={"green"}>
                            {fCurrency(rent_cost)} {t('price/day')}
                        </Typography>
                    )
                }
                <Stack display="flex" direction="row" className="aic">
                    <Box sx={{
                        width: '8px',
                        height: '8px',
                        borderRadius: '50%',
                        backgroundColor: status === 'vacant' ? 'green' : 'red'
                    }}
                    ></Box>
                    <Typography sx={{ ml: 1 }}>
                        {t(status as string)}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default React.memo(CarCard)