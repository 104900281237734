import { Box, Button, ButtonGroup, Dialog, DialogContent, DialogTitle, Grid, TextField, Select, FormControl, InputLabel,  Typography, MenuItem } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { Dayjs } from 'dayjs'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import ruLocale from 'date-fns/locale/ru'
import enLocal from 'date-fns/locale/en-US'
import uzLocal from 'date-fns/locale/uz'
import { useForm } from 'react-hook-form'
import { ICreateOrder } from '../../models'
import Input from '../input'
import { fDashedDate, fTime } from '../../utils/utils'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { fetchCreateOrder, fetchDownloadOrder } from '../../redux/actions/ordersActions'
import { appSlice } from '../../redux/reducers/appReducer'
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useFormState } from '../../hooks/use-form'

interface IProps {
    car_id: number
    client_id: number
    open: boolean
    onClose: () => void
    bookOnly: boolean
}
const server_url = 'https://4set.uz'

const CreateOrderModal = (props: IProps) => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [startDay, setStartDay] = useState<Date | null>(null);
    const [endDay, setEndDay] = useState<Date | null>(null);

    const [startTime, setStartTime] = useState<Dayjs | null>(null)
    const [endTime, setEndTime] = useState<Dayjs | null>(null)

    const { t } = useTranslation()
    const { results: orders } = useAppSelector((state) => state.ordersReducer);
    const { car_id, client_id, open, onClose, bookOnly } = props

    const foundOrder = useMemo(() => {
        return !!car_id ? orders.filter(order => order.car_id === car_id) : null
    }, [car_id, orders])

    const minStartDate = useMemo(() => {
        const notFinished = foundOrder?.find(order => order.status !== "finished") || null
        return foundOrder ? notFinished : null
    }, [foundOrder])

    const { control, handleSubmit, formState: { errors, submitCount } } = useForm<ICreateOrder>()

    const service = useFormState(errors, submitCount)

    const rentCar = (data: ICreateOrder) => {
        createOrder(data, false)
    }

    const reserveCar = (data: ICreateOrder) => {
        createOrder(data, true)
    }

    let lang = '';
    const options = [
    { value: 'uz', label: 'Узбекский' },
    { value: 'ru', label: 'Русский' },
    ];

    const [selectedValue, setSelectedValue] = useState(''); // Initial state
    const handleSelectChange = (event: { target: { value: React.SetStateAction<string> } }) => {
            setSelectedValue(event.target.value);
        };

    const createOrder = async (data: ICreateOrder, book: boolean) => {
        data.end = fDashedDate(endDay)
        data.start = fDashedDate(startDay)
        data.start_time = fTime(startTime)
        data.end_time = fTime(endTime)
        data.car_id = car_id
        data.client_id = client_id
        console.log(selectedValue)
        const fd = new FormData()

        for (const [key, value] of Object.entries(data)) {
            fd.append(key, value)
        }

        book && fd.append('status', 'reserved')

        //@ts-ignore
        const res = await dispatch(fetchCreateOrder(fd))
        if (res?.status === 201) {
            dispatch(appSlice.actions.toggleSystemAlert({ text: t(book ? 'bookSuccess' : 'rentSuccess'), severity: "success", show: true, autoHide: true }))
            const res2 = await dispatch(fetchDownloadOrder(res?.data.order_id, selectedValue))
            if (res2?.status === 200) {
                const link = document.createElement("a")
                link.href = `${server_url}/${res2?.data.document}`
                link.download = `${res2?.data.filename}`
                link.click()
            }
            navigate('/app/orders')
        }
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth={true}>
            <DialogTitle>
                <Typography variant="h5" component="h5">
                    {t('newOrder')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
                    <form onSubmit={handleSubmit(rentCar)}>
                        <Grid container spacing={2}>
                            <Grid item sm={12} md={6}>
                                <Typography variant="h6" component="h6">
                                    {t('startDate')}
                                </Typography>
                                <DatePicker
                                    value={startDay}
                                    onChange={(newValue) => {
                                        setStartDay(newValue);
                                    }}
                                    minDate={minStartDate ? moment(minStartDate.end).add(minStartDate.end_time).add(1, 'day').toDate() : null}
                                    renderInput={(params) => <TextField size="small" sx={{ mt: 1 }} {...params} />}
                                />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <Typography variant="h6" component="h6">
                                    {t('endDate')}
                                </Typography>
                                <DatePicker
                                    value={endDay}
                                    onChange={(newValue) => {
                                        setEndDay(newValue);
                                    }}
                                    minDate={minStartDate ? moment(minStartDate.end).add(minStartDate.end_time).add(1, 'day').toDate() : null}
                                    renderInput={(params) => <TextField size="small" sx={{ mt: 1 }} {...params} />}
                                />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <Typography variant="h6" component="h6">
                                    {t('startTime')}
                                </Typography>
                                <TimePicker
                                    value={startTime}
                                    onChange={(newValue) => {
                                        setStartTime(newValue);
                                    }}
                                    renderInput={(params) => <TextField size="small" sx={{ mt: 1 }} {...params} />}
                                />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <Typography variant="h6" component="h6">
                                    {t('endTime')}
                                </Typography>
                                <TimePicker
                                    value={endTime}
                                    onChange={(newValue) => {
                                        setEndTime(newValue);
                                    }}
                                    renderInput={(params) => <TextField size="small" sx={{ mt: 1 }} {...params} />}
                                />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <Input
                                    name="pledge"
                                    control={control}
                                    errors={errors}
                                    label={t('pledge')}
                                />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <Input
                                    name="region"
                                    control={control}
                                    errors={errors}
                                    label={t('region')}
                                    required={true}
                                />
                            </Grid>

                            <Grid item sm={12} md={6}>
                                <Input
                                    name="total_cost"
                                    control={control}
                                    errors={errors}
                                    type={"number"}
                                    label={t('order_total_cost')}
                                    // required={true}
                                />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <Input
                                    name="notes"
                                    control={control}
                                    errors={errors}
                                    label={t('order_notes')}
                                    // required={true}
                                />
                            </Grid>
                            <Grid item sm={12} md={6} >
                                <FormControl fullWidth sx={{width: '200px'}}>
                                    <InputLabel>{t('document_lang')}</InputLabel>
                                    <Select
                                        name="lang"
                                        value={selectedValue}
                                        onChange={handleSelectChange}
                                    >
                                        {/* Map through your options and create MenuItem for each */}
                                        {options.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Box className="flex aic jcc" sx={{ mt: 2 }}>
                            <ButtonGroup variant="outlined">
                                <Button color="primary" onClick={handleSubmit(reserveCar)}>
                                    {t('reserve')}
                                </Button>
                                {
                                    !bookOnly && (
                                        <Button color="success" type="submit">
                                            {t('rent')}
                                        </Button>
                                    )
                                }
                            </ButtonGroup>
                        </Box>
                    </form>
                </LocalizationProvider>
            </DialogContent>
        </Dialog>
    )
}

export default React.memo(CreateOrderModal)