import axios from 'axios'

export default {
  getClients(payload: string) {
    return axios.get(!!payload ? payload : "/api/clients/");
  },
  clientsFilter(payload: string) {
    return axios.get(`/api/clients/?search=${payload}`);
  },
  createClient(payload: FormData) {
    return axios.post("/api/clients/", payload);
  },
  editClient(payload: FormData, clientId: number) {
    return axios.put(`/api/clients/${clientId}/`, payload);
  },
  deleteClient(clientId: number) {
    return axios.delete(`/api/clients/${clientId}/`);
  },
  editClientPhoto(photoId: number, file: FormData) {
    return axios.put(`/api/client/photo/${photoId}/`, file);
  },
  blockClient(payload: FormData){
    return axios.post("/api/black_list/", payload);
  },
  unblockClient(clientId: number){
    return axios.delete(`/api/black_list/${clientId}/`);
  }
};
