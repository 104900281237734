import React, { useEffect } from 'react'
import { Alert, AlertTitle, Box } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { useTranslation } from 'react-i18next'
import { appSlice } from '../../redux/reducers/appReducer'
import {ALERT_TIMEOUT} from "../../const";

const AlertBox = () => {

    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const alert = useAppSelector(state => state.appReducer.alert)

    useEffect(() => {
        if (alert.show && alert.autoHide) {
            setTimeout(() => {
                hideAlert()
            }, ALERT_TIMEOUT)
        }
    }, [alert])

    const hideAlert = () => {
        dispatch(appSlice.actions.toggleSystemAlert({ show: false, severity: "info", text: '' }))
    }

    if (!alert.show) return null

    return (
        <Box sx={{
            position: 'fixed',
            top: '2rem',
            left: '3rem',
            zIndex: 1111
        }}>
            <Alert 
                onClose={() => hideAlert()}
                severity={alert.severity}
            >
                <AlertTitle>{t('alertTitles.' + alert.severity)}</AlertTitle>
                {alert.text}
            </Alert>
        </Box>
    )
}

export default React.memo(AlertBox)