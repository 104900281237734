interface IClientOrderPhoto {
    caption: null | string
    file: string
    id: number
    is_main: boolean
}

export interface IOrderExtend {
    order_id: number
    to_date: string | null
    to_time: string | null
}

export interface IOrder {
    order_id: number
    rent_id: number
    client_id: number
    car_id: number
    timestamp: string
    start: string
    end: string
    status: string
    start_time: string
    end_time: string
    order_number: number
    firstname: string
    lastname: string
    passport: string
    client_photo: IClientOrderPhoto[]
    brand: string
    model: string
    car_photo: string
    car_num: string
    is_extended: boolean
    extended_to: null | string
    client_phone: string
    total_cost: number
    notes: string
    pledge: string
}

export interface ICreateOrder {
    car_id: number
    client_id: number
    start: string
    start_time: string
    end: string
    end_time: string
    pledge: string
    region: string
}

export const orderProlongModel: IOrderExtend = {
    order_id: 0,
    to_date: null,
    to_time: null
}
