import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';

const BlackListClientAddButton = () => {

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };


    const {t} = useTranslation()
    return (
        <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
            <Link to={`/app/black_list_add`} style={{textDecoration: 'none'}}>
                <Button
                    key={'/black_list_add'}
                    variant="outlined"
                    onClick={handleCloseNavMenu}
                    sx={{my: 2, display: 'block'}}
                >
                    {t('black_list_add')}
                </Button>
            </Link>
        </Box>
    );
};
export default BlackListClientAddButton;
