import { Autocomplete, TextField } from '@mui/material'
import React from 'react'
import { Control, Controller, UseFormSetValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { allCountries } from '../../const'
import { IClient } from '../../models'

interface IProps {
    control: Control<IClient>
    setValue: UseFormSetValue<IClient>
}

const CountryAutocomplete = ({control, setValue}: IProps) => {

    const {t} = useTranslation()

    const handleCountryChange = (value: {name: string, code: string} | null) => {
        value ? setValue('citizenship', value.name) : setValue('citizenship', "")
    }

    const returnOptionLabel = (value: {name: string, code: string}) => {
        return `${value.code} - ${value.name}`
    }

    return (
        <Controller 
            control={control}
            name="citizenship"
            render={({field: { value, ref }}) => (
                <Autocomplete
                    options={allCountries}
                    value={allCountries.find(country => country.name === value) || null}
                    onChange={(e, data) => handleCountryChange(data)}
                    getOptionLabel={(option) => returnOptionLabel(option)}
                    renderInput={(params) => <TextField {...params} inputRef={ref} size="small" label={t('citizenship')} />}
                />
            )}
        />
    )
}

export default React.memo(CountryAutocomplete)