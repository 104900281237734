import * as yup from 'yup'

export interface IBlackList {
    id?: number
    passport_series: string
    firstname: string
    lastname: string
    second_name: string
    description: string
    photo?: any
}

export const BlackListModel: IBlackList = {
    passport_series: "",
    firstname: "",
    lastname: "",
    second_name: "",
    description: ""
}

export const blacklistSchema = yup.object({
    passport_series: yup.string().min(5).max(20).required(),
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    second_name: yup.string().required(),
    description: yup.string().required(),
}).required()