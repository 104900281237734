import { Box, Button, ButtonGroup, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {CarCard, CarsFilter} from '../components'
import {ClientCard, ClientsFilter} from '../components/clients'
import { useAppDispatch, useAppSelector } from '../hooks/redux'
import { fetchCars } from '../redux/actions/carsActions'
import { fetchClients } from '../redux/actions/clientsActions'
import ArrowForward from '@mui/icons-material/ArrowForward'
import { useToggle } from '../hooks/use-toggle'
import CreateOrderModal from '../components/createOrder/CreateOrderModal'
import {appSlice} from "../redux/reducers/appReducer";
import { fetchOrders } from '../redux/actions/ordersActions'

const CreateOrderPage = () => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const modal = useToggle(false)

    const bookOnly = useToggle(false)

    const [car_id, setCarId] = useState<number | null>(null)
    const [client_id, setClientId] = useState<number | null>(null)

    const { cars: carsList, paginationData: { previous: carsPrev, next: carsNext } } = useAppSelector(state => state.carsReducer) || null
    const { results: clientsList, next: clientsNext, previous: clientsPrev } = useAppSelector(state => state.clientsReducer) || null

    const foundCar = carsList.find(car => car.car_id === car_id)
    const foundClient = clientsList.find(client => client.client_id === client_id)

    const selectCar = useCallback((carId: number, status: 'vacant' | 'occupied') => {
        carId ? setCarId(carId) : setCarId(null)
        if (status === 'occupied') {
            bookOnly.on()
            dispatch(appSlice.actions.toggleSystemAlert({
                text: t('carOccupied'),
                severity: 'info',
                show: true,
                autoHide: true
            }))
        } else {
            bookOnly.off()
        }
    }, [])

    const selectClient = useCallback((clientId: number, status: 'vacant' | 'occupied') => {
        clientId ? setClientId(clientId) : setClientId(null)
        if (status === 'occupied') {
            bookOnly.on()
            dispatch(appSlice.actions.toggleSystemAlert({
                text: t('clientOccupied'),
                severity: 'info',
                show: true,
                autoHide: true
            }))
        }
    }, [])

    const clientChangePage = useCallback((variant: "next" | "prev") => {
        if (variant === "next") {
            dispatch(fetchClients(clientsNext))
        } else {
            dispatch(fetchClients(clientsPrev))
        }
    }, [])

    const carChangePage = useCallback((variant: "next" | "prev") => {
        if (variant === "next") {
            dispatch(fetchCars(carsNext))
        } else {
            dispatch(fetchCars(carsPrev))
        }
    }, [])

    const openOrderModal = useCallback(() => {
        modal.on()
    }, [])

    useEffect(() => {
        dispatch(fetchClients(''))
        dispatch(fetchCars(''))
        dispatch(fetchOrders(''))
    }, [])

    return (
        <>
            <Box sx={{ p: { xs: 1, md: 0 }, maxWidth: '1400px', margin: '0 auto' }}>
                <CreateOrderModal 
                    car_id={car_id as number}
                    client_id={client_id as number}
                    open={modal.state}
                    onClose={() => modal.off()}
                    bookOnly={bookOnly.state}
                />
                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: '44% 2% 44%',
                    justifyContent: 'space-between',
                    gridGap: '10px'
                }}>
                    {/* carslist */}
                    <Stack display="flex" direction="column">
                        <CarsFilter padding={false} />
                        {
                            car_id && (
                                <Typography variant='h5' component="h5" sx={{ mt: 2 }}>
                                    {t('carSelecting')} &nbsp; {`${foundCar?.brand} ${foundCar?.model} ${foundCar?.car_num}`}
                                </Typography>
                            )
                        }
                        <Grid container spacing={3} sx={{mt: 2}}>
                            {
                                carsList.map(car => (
                                    <Grid item key={car.car_id} onClick={() => selectCar(car.car_id as number, car?.status as 'vacant' | 'occupied')} sm={12} md={6}>
                                        <CarCard {...car} small={true} isSelected={car.car_id === car_id} />
                                    </Grid>
                                ))
                            }
                        </Grid>
                        <ButtonGroup variant='outlined' sx={{ mt: 2 }}>
                            <Button
                                onClick={() => carChangePage('prev')}
                                color="error"
                                disabled={!Boolean(carsPrev)}
                            >
                                {t('previous')}
                            </Button>
                            <Button
                                onClick={() => carChangePage('next')}
                                disabled={!Boolean(carsNext)}
                            >
                                {t('next')}
                            </Button>
                        </ButtonGroup>
                    </Stack>
                    <Stack direction="column" className="aic">
                        {
                            (car_id || client_id) && (
                                <Typography variant='h5' component="h5" sx={{ my: 2 }}>
                                    {t('for')}
                                </Typography>
                            )
                        }
                        <Divider orientation="vertical" />
                    </Stack>
                    {/* clientsList */}
                    <Stack direction="column">
                        <ClientsFilter />
                        {
                            client_id && (
                                <Typography variant='h5' component="h5" sx={{ mt: 2 }}>
                                    {`${foundClient?.firstname} ${foundClient?.lastname} ${foundClient?.passport_series}`}
                                    {car_id && (
                                        <Tooltip
                                            title={t('createOrder')}
                                            arrow
                                            placement="top"
                                            onClick={() => openOrderModal()}
                                        >
                                            <IconButton sx={{ ml: 2 }} color="primary">
                                                <ArrowForward />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Typography>
                            )
                        }
                        <Grid container spacing={3} sx={{mt: 2}}>
                            {
                                clientsList.map(client => (
                                    <Grid item key={client.client_id} onClick={() => selectClient(client.client_id as number, client?.status as 'vacant' | 'occupied')} sm={12} md={6}>
                                        <ClientCard {...client} isSelected={client.client_id === client_id} />
                                    </Grid>
                                ))
                            }
                        </Grid>
                        <ButtonGroup variant='outlined' sx={{ mt: 2 }}>
                            <Button
                                onClick={() => clientChangePage('prev')}
                                color="error"
                                disabled={!Boolean(clientsPrev)}
                            >
                                {t('previous')}
                            </Button>
                            <Button
                                onClick={() => clientChangePage('next')}
                                disabled={!Boolean(clientsNext)}
                            >
                                {t('next')}
                            </Button>
                        </ButtonGroup>
                    </Stack>
                </Box>
            </Box>
        </>
    )
}

export default React.memo(CreateOrderPage)