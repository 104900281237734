import { Box, Button, ButtonGroup, Grid } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {BlackListCard, BlackListModal, BlackListClientsFilter, BlackListClientAddButton } from '../components/blackList'
import { useAppDispatch, useAppSelector } from '../hooks/redux'
import { useToggle } from '../hooks/use-toggle'
import { fetchBlackListClients } from '../redux/actions/blacklistActions'

const BlackList = () => {

    const { results: clientsList, next: nextPage, previous: prevPage } = useAppSelector(state => state.blacklistReducer)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { id } = useParams()

    const modal = useToggle(false)

    useEffect(() => {
        dispatch(fetchBlackListClients(''))
    }, [])

    const openModal = (clientId: number) => {
        navigate(`/app/black_list/${clientId}/`)
        modal.on()
    }

    const handlePageChange = useCallback((type: "next" | "previous") => {
        if (type === "next") {
            dispatch(fetchBlackListClients(nextPage))
        } else {
            dispatch(fetchBlackListClients(prevPage))
        }
    }, [nextPage, prevPage])

    return (
        <>
            <Box sx={{ p: { xs: 1, md: 0 }, maxWidth: '1400px', margin: '0 auto' }}>
                <BlackListClientsFilter />
                <BlackListClientAddButton/>
                <Grid container spacing={5} sx={{ mt: 3 }}>
                    {clientsList.map(client => (
                        <Grid item key={client.id} xs={12} sm={6} md={4} lg={3} onClick={() => openModal(client.id as number)}>
                            <BlackListCard {...client} />
                        </Grid>
                    ))}
                </Grid>
                <Box className='flex jcc aic'>
                    <ButtonGroup variant="outlined" sx={{ mt: 4 }}>
                        <Button
                            onClick={() => handlePageChange("previous")}
                            disabled={!Boolean(prevPage)}
                        >
                            {t('previous')}
                        </Button>
                        <Button
                            onClick={() => handlePageChange('next')}
                            disabled={!Boolean(nextPage)}
                        >
                            {t('next')}
                        </Button>
                    </ButtonGroup>
                </Box>
            </Box>
            {modal.state && <BlackListModal id={id || "car-id"} modal={modal} key="item" />}
        </>
    )
}

export default React.memo(BlackList)