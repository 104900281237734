import { useCallback, useEffect, useState } from "react";
import axios from 'axios'

export function useAuth() {
  const [token, setToken] = useState<string | null>(null);

  const login = useCallback((jwtToken: string) => {
    setToken(jwtToken);

    localStorage.setItem("token", JSON.stringify(jwtToken));
    axios.defaults.headers.common["Authorization"] = `Token ${jwtToken}`
  }, []);

  const logout = useCallback(() => {
    setToken(null);

    localStorage.removeItem("token");
    localStorage.removeItem(`newCreatedUserData`);
    localStorage.removeItem('user_type')
  }, []);

  useEffect(() => {
    
    const data = JSON.parse(localStorage.getItem('token') || '""');

    if (data) {
      login(data);
    }
 
  }, []);

  return { login, logout, token };
}
