import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
// import AdbIcon from '@mui/icons-material/Adb';
import {useTranslation} from 'react-i18next';
import {NAVBAR_HEIGHT} from '../../const';
import {Link, useNavigate} from 'react-router-dom';
import i18next from "i18next";
import {logoutUser} from "../../redux/actions/axiosReq";
import {useAuth} from "../../hooks/use-auth";
import {useAppDispatch} from "../../hooks/redux";
import {appSlice} from "../../redux/reducers/appReducer";

const get_user_navbar = (user_type:any) => {
    if (user_type === "MAIN_RENT"){
        return [
        //  {
        //     label: 'addClient',
        //     path: '/addclient'
        // },
        {
            label: 'createOrder',
            path: '/create_order'
        },
        {
            label: 'orders',
            path: '/orders'
        },
        {
            label: 'cars',
            path: '/carslist'
        },
        {
            label: 'allClients',
            path: '/clientslist'
        },
        // {
        //     label: 'add_car',
        //     path: '/car_add'
        // },
        {
            label: 'black_list',
            path: '/black_list'
        },
        // {
        //     label: 'black_list_add',
        //     path: '/black_list_add'
        // },
        {
            label: 'car_repair',
            path: '/car_repair'
        }
    ];
    }
    else if (user_type === "SECONDARY_RENT") {
        return [
        //  {
        //     label: 'addClient',
        //     path: '/addclient'
        // },
        {
            label: 'createOrder',
            path: '/create_order'
        },
        {
            label: 'orders',
            path: '/orders'
        },
        {
            label: 'cars',
            path: '/carslist'
        },
        {
            label: 'allClients',
            path: '/clientslist'
        },
        // {
        //     label: 'add_car',
        //     path: '/car_add'
        // },
        {
            label: 'black_list',
            path: '/black_list'
        },
        // {
        //     label: 'black_list_add',
        //     path: '/black_list_add'
        // },
        {
            label: 'car_repair',
            path: '/car_repair'
        }
    ];
    }
    return []
}

const user_type = localStorage.getItem('user_type')
const pages = get_user_navbar(user_type)
const settings = ['Logout'];
const langs = ['ru', 'uz', 'en']

const ResponsiveAppBar = () => {

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [anchorElLang, setAnchorElLang] = React.useState<null | HTMLElement>(null)

    const dispatch = useAppDispatch()
    const auth = useAuth()

    const handleOpenLangMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElLang(event.currentTarget)
    }

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseLangMenu = () => {
        setAnchorElLang(null)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLanguage = (l: string) => {
        i18next.changeLanguage(l)
        handleCloseLangMenu()
        handleCloseUserMenu()
    }

    const logout = async () => {
        const res = await logoutUser()
        if (res?.status === 200) {
            auth.logout()
        }
        dispatch(appSlice.actions.toggleAuth(false))
        handleCloseUserMenu()
    }

    const navigate = useNavigate()

    const {t} = useTranslation()

    return (
        <AppBar position="fixed" sx={{height: NAVBAR_HEIGHT + 15}}>
            <Container sx={{maxWidth: '1400px !important', p: {xs: 1, md: '0 !important'}}}>
                <Toolbar sx={{maxWidth: '1400px'}} disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/app"
                        sx={{
                            mr: 2,
                            display: {xs: 'none', md: 'flex'},
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        FORSET
                    </Typography>

                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}
                        >
                            {pages.map((page) => (
                                <Link to={`/app${page.path}`} style={{textDecoration: 'none'}}>
                                    <MenuItem
                                        key={page.path}
                                        onClick={handleCloseNavMenu}
                                    >
                                        <Typography textAlign="center">{t(page.label)}</Typography>
                                    </MenuItem>
                                </Link>
                            ))}
                        </Menu>
                    </Box>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                            mr: 2,
                            display: {xs: 'flex', md: 'none'},
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        FORSET
                    </Typography>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        {pages.map((page) => (
                            <Link to={`/app${page.path}`} style={{textDecoration: 'none'}}>
                                <Button
                                    key={page.path}
                                    onClick={handleCloseNavMenu}
                                    sx={{my: 2, color: 'white', display: 'block'}}
                                >
                                    {t(page.label)}
                                </Button>
                            </Link>
                        ))}
                        {/*<Button*/}
                        {/* sx={{my: 2, color: 'white', display: 'block'}}>*/}
                        {/*    'wefw'*/}
                        {/*</Button>*/}
                    </Box>

                    <Box sx={{flexGrow: 0}}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg"/>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{mt: '45px'}}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem onClick={handleOpenLangMenu}>
                                <Typography textAlign="center">{t('language')}</Typography>
                            </MenuItem>
                            <Menu
                                open={Boolean(anchorElLang)}
                                anchorEl={anchorElLang}
                                onClose={handleCloseLangMenu}
                            >
                                {
                                    langs.map(l => (
                                        <MenuItem key={l} onClick={() => handleLanguage(l)}>
                                            <Typography textAlign="center">{l.toUpperCase()}</Typography>
                                        </MenuItem>
                                    ))
                                }
                            </Menu>
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={logout}>
                                    <Typography textAlign="center">{t(setting.toLowerCase())}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default ResponsiveAppBar;
