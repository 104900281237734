import {
    Button,
    CardMedia,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Stack,
    Tooltip,
    Typography, useMediaQuery,
    useTheme
} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from '../../hooks/redux'
import {useNav} from '../../hooks/use-nav'
import {useToggle} from '../../hooks/use-toggle'
import {BlackListModel, IBlackList} from '../../models'
import {copyObject} from '../../utils/utils'
import Close from '@mui/icons-material/Close'
import Edit from '@mui/icons-material/Edit'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {useForm} from 'react-hook-form'
import Input from '../input'
import {
    fetchBlockClient,
    fetchBlackListClients,
    fetchUnBlockClient,
    fetchEditClientBlackList

} from "../../redux/actions/blacklistActions";
import ArrowRightAlt from "@mui/icons-material/ArrowRightAlt";
import {appSlice} from "../../redux/reducers/appReducer";
// import { useFormState } from '../../hooks/use-form'


// import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Delete from "@mui/icons-material/Delete";
import UploadFile from "@mui/icons-material/UploadFile";
import {fetchClients} from "../../redux/actions/clientsActions"; // Import carousel styles


interface IProps {
    id: string
    modal: {
        state: boolean
        on: () => void
        off: () => void
        toggle: () => void
    }
}

const BlackListClientModal = ({modal}: IProps) => {

    const {id} = useParams()
    const edit = useToggle(false)
    const {goBack} = useNav()
    const {t} = useTranslation()
    const dispatch = useAppDispatch()

    const theme = useTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))

    const [clientImg, setClientImg] = useState<any>(null)
    const clientsList = useAppSelector(state => state.blacklistReducer.results)
    const foundClient = clientsList.find(client => `${client.id}` === id) || copyObject(BlackListModel) as IBlackList
    const {
        firstname,
        lastname,
        second_name,
        passport_series,
        description
    } = foundClient

    // const [selectedCarouselIndex, setSelectedCarouselIndex] = useState(0);

    const close = () => {
        modal.off()
        edit.off()
        goBack()
    }

    const {handleSubmit, control, formState: {errors, submitCount}} = useForm<IBlackList>({
        defaultValues: copyObject(foundClient)
    })

    // const service = useFormState(errors, submitCount)

    const handleClientImg = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setClientImg(event.target.files[0])
        } else {
            setClientImg(null)
        }
    }


    const editClient = async (data: IBlackList) => {
        const formData = new FormData()
        // delete data.photo
        for (const [key, value] of Object.entries(data)) {
            formData.append(key, value)
        }
        clientImg && formData.append("photo", clientImg)
        const res = await dispatch(fetchEditClientBlackList(formData, foundClient.id as number))
        if (res?.status === 200) {
            dispatch(appSlice.actions.toggleSystemAlert({
                text: t('clientEditSuccess'),
                severity: 'success',
                show: true,
                autoHide: true
            }))
            close()
        }
        else {
             dispatch(appSlice.actions.toggleSystemAlert({
                    text: t('clientUnblockError'),
                    severity: 'error',
                    show: true,
                    autoHide: true
                }))
             close()
        }
    }

    const unblockClient = async () => {
        const res = await dispatch(fetchUnBlockClient(foundClient?.id as number))
        if (res?.status === 204) {
            dispatch(appSlice.actions.toggleSystemAlert({
                    text: t('clientUnblockSuccess'),
                    severity: 'success',
                    show: true,
                    autoHide: true
                }))
            dispatch(fetchBlackListClients(''))
            close()
        }
        else {
             dispatch(appSlice.actions.toggleSystemAlert({
                    text: t('clientUnblockError'),
                    severity: 'error',
                    show: true,
                    autoHide: true
                }))
             close()
        }
    }

    return (
        <Dialog sx={{transition: '0.3s'}} open={modal.state} onClose={() => close()} maxWidth={"md"}>
            <Stack direction={sm ? 'column' : 'row'} sx={{p: 1, transition: '0.3s'}}>
                <Stack display="flex" direction={sm ? 'row' : 'column'} justifyContent={sm ? 'center' : 'start'}>
                    <Tooltip
                        title={"Закрыть"}
                        arrow
                        disableInteractive
                        placement={"left"}
                    >
                        <IconButton color={"error"} onClick={() => close()}>
                            <Close/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title={"Редактировать"}
                        arrow
                        disableInteractive
                        placement={"left"}
                    >
                        <IconButton color={"info"} onClick={() => edit.toggle()}>
                            <Edit/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title={t('unblock_client')}
                        arrow
                        disableInteractive
                        placement={"left"}
                        >
                        <IconButton color={"error"} onClick={() => unblockClient()}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </Stack>
                <Stack direction={{sm: 'column', md: edit.state ? 'column-reverse' : 'row'}}>
                    {
                        edit.state ? (
                            <>
                                <DialogContent>
                                    <form onSubmit={handleSubmit(editClient)}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Input
                                                    label={t('lastName')}
                                                    fullWidth
                                                    control={control}
                                                    name="lastname"
                                                    errors={errors}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Input
                                                    label={t('firstName')}
                                                    fullWidth
                                                    name="firstname"
                                                    control={control}
                                                    errors={errors}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Input
                                                    label={t('fathersName')}
                                                    fullWidth
                                                    control={control}
                                                    name="second_name"
                                                    errors={errors}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Input
                                                    label={t('passport')}
                                                    fullWidth
                                                    control={control}
                                                    name="passport_series"
                                                    errors={errors}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Input
                                                    label={t('description')}
                                                    fullWidth
                                                    control={control}
                                                    name="description"
                                                    errors={errors}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <>
                                                    <IconButton sx={{mt: 2}} color="primary" aria-label="upload image"
                                                                component="label">
                                                        <input hidden accept="image/*" type="file"
                                                               onChange={handleClientImg}/>
                                                        <UploadFile/>
                                                    </IconButton>
                                                    {
                                                        clientImg && (
                                                            <Typography>
                                                                {clientImg.name}
                                                            </Typography>
                                                        )
                                                    }
                                                </>
                                            </Grid>
                                        </Grid>
                                        <br/>
                                        <Button sx={{mt: 2}} variant={"outlined"} color={"success"} type={"submit"}>
                                            {t('save')}
                                        </Button>

                                    </form>
                                </DialogContent>
                            </>
                        ) : (
                            <DialogTitle>
                                <Typography variant={"h5"} component={"div"}>
                                    {lastname}
                                </Typography>
                                <Typography variant={"h5"} component={"div"}>
                                    {firstname}
                                </Typography>
                                <Typography variant={"h5"} component={"div"}>
                                    {second_name}
                                </Typography>
                                <Typography sx={{mt: 1}}>
                                    {passport_series}
                                </Typography>
                                <Divider sx={{my: 1}}/>
                            </DialogTitle>
                        )
                    }
                    <DialogContent className={`flex jcc aic ${sm ? 'col' : 'row'}`}>
                        <div style={{ width: '200px' }}>
                            <Typography variant={"h5"} component={"div"}>
                                    {description}
                                </Typography>
                      {clientImg && (
                        <>
                          <ArrowRightAlt
                            sx={{ width: '4rem', transform: sm ? 'rotate(90deg)' : 'rotate(0)', fontSize: 50 }}
                          />
                          <CardMedia
                            component="img"
                            sx={{ height: 200, width: sm ? 250 : 300 }}
                            image={URL.createObjectURL(clientImg)}
                          />
                        </>
                      )}
                    </div>
                    </DialogContent>
                </Stack>
            </Stack>
        </Dialog>
    )
}

export default React.memo(BlackListClientModal)