import {
    Box, Button,
    CardMedia,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Stack,
    Tooltip,
    Typography, useMediaQuery,
    useTheme
} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from '../../hooks/redux'
import {useNav} from '../../hooks/use-nav'
import {useToggle} from '../../hooks/use-toggle'
import {clientModel, IClient} from '../../models'
import {copyObject} from '../../utils/utils'
import Close from '@mui/icons-material/Close'
import Edit from '@mui/icons-material/Edit'
import Download from '@mui/icons-material/Download'
import Print from '@mui/icons-material/Print'
import Block from '@mui/icons-material/Block'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import no_avatar from '../../assets/no_img.jpg'
import {useForm} from 'react-hook-form'
import Input from '../input'
import {CustomDatePicker} from '../datepicker'
import Delete from '@mui/icons-material/Delete'
import {
    fetchBlockClient,
    fetchClients,
    fetchDeleteClient,
    fetchEditClient,
    fetchEditClientPhoto, fetchUnBlockClient
} from "../../redux/actions/clientsActions";
import UploadFile from "@mui/icons-material/UploadFile";
import ArrowRightAlt from "@mui/icons-material/ArrowRightAlt";
import {appSlice} from "../../redux/reducers/appReducer";
import { useFormState } from '../../hooks/use-form'


// import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import { Carousel } from 'react-responsive-carousel';
// import CountryAutocomplete from "../countryAutocomplete/CountryAutocomplete";


interface IProps {
    id: string
    modal: {
        state: boolean
        on: () => void
        off: () => void
        toggle: () => void
    }
}
const server_url = 'https://4set.uz'

const ClientModal = ({modal}: IProps) => {

    const {id} = useParams()
    const edit = useToggle(false)
    const {goBack} = useNav()
    const {t} = useTranslation()
    const dispatch = useAppDispatch()

    const theme = useTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))

    const [clientImg, setClientImg] = useState<any>(null)
    const clientsList = useAppSelector(state => state.clientsReducer.results)
    const foundClient = clientsList.find(client => `${client.client_id}` === id) || copyObject(clientModel) as IClient
    const {
        firstname,
        lastname,
        second_name,
        photo,
        citizenship,
        date_of_issue,
        issued,
        address,
        passport_series,
        in_black_list,
        status,
        phone
    } = foundClient

    const [selectedCarouselIndex, setSelectedCarouselIndex] = useState(0);

    const handleCarouselChange = (index: any) => {
        setSelectedCarouselIndex(index);
        console.log(selectedCarouselIndex)
    };

    const close = () => {
        modal.off()
        edit.off()
        goBack()
    }

    const {handleSubmit, control, formState: {errors, submitCount}} = useForm<IClient>({
        defaultValues: copyObject(foundClient)
    })

    const service = useFormState(errors, submitCount)

    const handleClientImg = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setClientImg(event.target.files[0])
            console.log('upload photo', selectedCarouselIndex)

            const file = new FormData()
            file.append('file', event.target.files[0])
            file.append('id', foundClient.photo[selectedCarouselIndex].id)
            const photoReq = await dispatch(fetchEditClientPhoto(Number(foundClient.photo[selectedCarouselIndex].id as string), file))
            if (photoReq?.status === 200) {
                dispatch(
                  appSlice.actions.toggleSystemAlert({
                    severity: "success",
                    show: true,
                    text: t("photoChangeSucces"),
                  })
                );
            }
        } else {
            setClientImg(null)
        }
    }, [])

    const downloadPassport = () => {
        const link = document.createElement("a")
        link.href = photo ? `${server_url}${photo[selectedCarouselIndex].file}` : no_avatar
        link.download = `Client image`
        link.target = '_blank'
        link.click()
    }

    // const printPassport = () => {
    //     const printWindow = window.open("about:blank", "PrintWindow");
    //     printWindow?.document.open();
    //     // @ts-ignore
    //     printWindow?.document.write(basicMarkup(photo));
    //     printWindow?.document.close();
    //     printWindow?.focus();
    //
    //     printWindow?.print();
    // }
    const printPassport = () => {
      const printWindow = window.open("about:blank", "PrintWindow");
      printWindow?.document.open();
      printWindow?.document.write(`
        <html>
          <body style="margin: 0; padding: 0;">
      `);

      photosArray.forEach((photo, index) => {
        printWindow?.document.write(`
          <div style="page-break-after: always; margin: 10px;">
            <img src="${server_url}${photo.file}" style="width: 100%; height: auto;">
          </div>
        `);
      });

      printWindow?.document.write(`
          </body>
        </html>
      `);

      printWindow?.document.close();
      printWindow?.focus();
      printWindow?.print();
      printWindow?.close();
    };

    const editClient = async (data: IClient) => {
        const formData = new FormData()
        delete data.photo
        for (const [key, value] of Object.entries(data)) {
            formData.append(key, value)
        }
        clientImg && formData.append("photo", clientImg)
        const res = await dispatch(fetchEditClient(formData, foundClient.client_id as number))
        if (res?.status === 200) {
            dispatch(appSlice.actions.toggleSystemAlert({
                text: t('clientEditSuccess'),
                severity: 'success',
                show: true,
                autoHide: true
            }))
            close()
        }
    }

    const blockClient = async (id: string) => {
        const message = window.prompt(t('block_description'))
        console.log(message)
        if (message === null || message === "" ){
            window.alert(t('block_description'))
        }
        else {
            const formData = new FormData()
            formData.append('client_id', id)
            formData.append('description', message as string)
            const res = await dispatch(fetchBlockClient(formData))
            if (res?.status === 201) {
                dispatch(appSlice.actions.toggleSystemAlert({
                    text: t('clientEditSuccess'),
                    severity: 'success',
                    show: true,
                    autoHide: true
                }))
                dispatch(fetchClients(''))
                close()
            }
        }

    }

    const unblockClient = async () => {
        const res = await dispatch(fetchUnBlockClient(foundClient?.client_id as number))
        if (res?.status === 204) {
            dispatch(appSlice.actions.toggleSystemAlert({
                    text: t('clientUnblockSuccess'),
                    severity: 'success',
                    show: true,
                    autoHide: true
                }))
            dispatch(fetchClients(''))
            close()
        }
        else {
             dispatch(appSlice.actions.toggleSystemAlert({
                    text: t('clientUnblockError'),
                    severity: 'error',
                    show: true,
                    autoHide: true
                }))
             close()
        }
    }
    const deleteClient = async () => {
        const ans = window.confirm(t('delete') + '?')
        if (ans) {
            const res = await dispatch(fetchDeleteClient(foundClient?.client_id as number))
            if (res?.status === 204) {
                dispatch(fetchClients(''))
                close()
            }
        }
    }
    const photosArray = Array.isArray(photo) && photo.length > 0 ? photo : Array({file: no_avatar});


    return (
        <Dialog sx={{transition: '0.3s'}} open={modal.state} onClose={() => close()} maxWidth={"md"}>
            <Stack direction={sm ? 'column' : 'row'} sx={{p: 1, transition: '0.3s'}}>
                <Stack display="flex" direction={sm ? 'row' : 'column'} justifyContent={sm ? 'center' : 'start'}>
                    <Tooltip
                        title={"Закрыть"}
                        arrow
                        disableInteractive
                        placement={"left"}
                    >
                        <IconButton color={"error"} onClick={() => close()}>
                            <Close/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title={"Редактировать"}
                        arrow
                        disableInteractive
                        placement={"left"}
                    >
                        <IconButton color={"info"} onClick={() => edit.toggle()}>
                            <Edit/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title={"Скачать фотографию паспорта"}
                        arrow
                        disableInteractive
                        placement={"left"}
                    >
                        <IconButton color={"info"} onClick={() => downloadPassport()}>
                            <Download/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title={"Распечатать фотографию паспорта"}
                        arrow
                        disableInteractive
                        placement={"left"}
                    >
                        <IconButton color={"info"} onClick={() => printPassport()}>
                            <Print/>
                        </IconButton>
                    </Tooltip>
                    {
                        !in_black_list ? (
                        <Tooltip
                            title={t('block_client')}
                            arrow
                            disableInteractive
                            placement={"left"}
                        >
                            <IconButton color={"error"} onClick={() => blockClient(id as string)}>
                                <Block/>
                            </IconButton>
                        </Tooltip>
                        ):
                            (
                        <Tooltip
                            title={t('unblock_client')}
                            arrow
                            disableInteractive
                            placement={"left"}
                            >
                            <IconButton color={"error"} onClick={() => unblockClient()}>
                                <CheckCircleIcon />
                            </IconButton>
                        </Tooltip>
                        )
                    }

                    <Tooltip
                        title={t('delete')}
                        arrow
                        disableInteractive
                        placement={"left"}
                    >
                        <IconButton color={"error"} onClick={() => deleteClient()}>
                            <Delete/>
                        </IconButton>
                    </Tooltip>
                </Stack>
                <Stack direction={{sm: 'column', md: edit.state ? 'column-reverse' : 'row'}}>
                    {
                        edit.state ? (
                            <>
                                <DialogContent>
                                    <form onSubmit={handleSubmit(editClient)}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Input
                                                    label={t('lastName')}
                                                    fullWidth
                                                    control={control}
                                                    name="lastname"
                                                    errors={errors}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Input
                                                    label={t('firstName')}
                                                    fullWidth
                                                    name="firstname"
                                                    control={control}
                                                    errors={errors}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Input
                                                    label={t('fathersName')}
                                                    fullWidth
                                                    control={control}
                                                    name="second_name"
                                                    errors={errors}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Input
                                                    label={t('passport')}
                                                    fullWidth
                                                    control={control}
                                                    name="passport_series"
                                                    errors={errors}
                                                />
                                            </Grid>
                                            {/*<Grid item xs={12} sm={6} md={4}>*/}
                                            {/*    <CountryAutocomplete*/}
                                            {/*        // label={t('citizenship')}*/}
                                            {/*        setValue={setValue}*/}
                                            {/*        control={control}*/}
                                            {/*        // errors={errors}*/}
                                            {/*    />*/}
                                            {/*</Grid>*/}
                                            <Grid item xs={12} sm={6} md={4}>
                                                <CustomDatePicker
                                                    control={control}
                                                    errors={errors}
                                                    name="date_of_birth"
                                                    label={t('dateofbirth')}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Input
                                                    label={t('address')}
                                                    fullWidth
                                                    control={control}
                                                    name="address"
                                                    errors={errors}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Input
                                                    label={t('telephone')}
                                                    fullWidth
                                                    control={control}
                                                    name="phone"
                                                    errors={errors}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                {/* passport issue date */}
                                                <CustomDatePicker
                                                    control={control}
                                                    errors={errors}
                                                    name="date_of_issue"
                                                    fullWidth
                                                    label={t('dateofissue')}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Input
                                                    label={t('authorityTitle')}
                                                    fullWidth
                                                    control={control}
                                                    name="issued"
                                                    errors={errors}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <>
                                                    <IconButton sx={{mt: 2}} color="primary" aria-label="upload image"
                                                                component="label">
                                                        <input hidden accept="image/*" type="file"
                                                               onChange={handleClientImg}/>
                                                        <UploadFile/>
                                                    </IconButton>
                                                    {
                                                        clientImg && (
                                                            <Typography>
                                                                {clientImg.name}
                                                            </Typography>
                                                        )
                                                    }
                                                </>
                                            </Grid>
                                        </Grid>
                                        <br/>
                                        <Button sx={{mt: 2}} variant={"outlined"} color={"success"} type={"submit"}>
                                            {t('save')}
                                        </Button>

                                    </form>
                                </DialogContent>
                            </>
                        ) : (
                            <DialogTitle>
                                <Typography variant={"h5"} component={"div"}>
                                    {lastname}
                                </Typography>
                                <Typography variant={"h5"} component={"div"}>
                                    {firstname}
                                </Typography>
                                <Typography variant={"h5"} component={"div"}>
                                    {second_name}
                                </Typography>
                                <Typography sx={{mt: 1}}>
                                    {passport_series}
                                </Typography>
                                <Divider sx={{my: 1}}/>
                                <Typography display="flex" alignItems="center">
                                    <Box sx={{
                                        width: '8px',
                                        height: '8px',
                                        mr: 1,
                                        background: in_black_list ? 'red' : 'green',
                                        borderRadius: '50%'
                                    }}></Box> {t('blackList')}
                                </Typography>
                                <Typography display="flex" alignItems="center">
                                    <Box sx={{
                                        width: '8px',
                                        height: '8px',
                                        mr: 1,
                                        background: status === "occupied" ? 'red' : 'green',
                                        borderRadius: '50%'
                                    }}></Box> {t(status as string)}
                                </Typography>
                            </DialogTitle>
                        )
                    }
                    <DialogContent className={`flex jcc aic ${sm ? 'col' : 'row'}`}>
                        <div style={{ width: '200px' }}>
                      <Carousel showThumbs={false}
                        selectedItem={selectedCarouselIndex}
                        onChange={handleCarouselChange}
                      >
                        {photosArray.map((image, index) => (
                          <div key={index}>
                            <CardMedia
                              component="img"
                              sx={{ height: 200, width: 200 }}
                              image={`${server_url}${image.file}`}
                            />
                          </div>
                        ))}
                      </Carousel>
                      {clientImg && (
                        <>
                          <ArrowRightAlt
                            sx={{ width: '4rem', transform: sm ? 'rotate(90deg)' : 'rotate(0)', fontSize: 50 }}
                          />
                          <CardMedia
                            component="img"
                            sx={{ height: 200, width: sm ? 250 : 300 }}
                            image={URL.createObjectURL(clientImg)}
                          />
                        </>
                      )}
                    </div>
                    </DialogContent>
                    {/*<DialogContent className={`flex jcc aic ${sm ? 'col' : 'row'}`}>*/}
                    {/*    <CardMedia*/}
                    {/*        component={"img"}*/}
                    {/*        sx={{height: 200, width: 200}}*/}
                    {/*        image={!!photo ? (Array.isArray(photo) ? `${server_url}${photo[0].file}` : `${server_url}${photo}`) : no_avatar}*/}
                    {/*    />*/}
                    {/*    {*/}
                    {/*        clientImg && (*/}
                    {/*            <>*/}
                    {/*                <ArrowRightAlt  sx={{width: '4rem', transform: sm ? 'rotate(90deg)' : 'rotate(0)', fontSize: 50}}/>*/}
                    {/*                <CardMedia*/}
                    {/*                    component={"img"}*/}
                    {/*                    sx={{ height: 200, width: sm ? 250 : 300 }}*/}
                    {/*                    image={URL.createObjectURL(clientImg)}*/}
                    {/*                />*/}
                    {/*            </>*/}
                    {/*        )*/}
                    {/*    }*/}
                    {/*</DialogContent>*/}
                </Stack>
            </Stack>
            {
                !edit.state && (
                    <>
                        <Divider/>
                        <Stack
                            direction={{xs: 'column', sm: 'row'}}
                            sx={{px: 4, py: 2}}
                            justifyContent="space-between"
                        >
                            <Typography sx={{mt: 1}}>
                                {t('citizenship')} <br/> {citizenship}
                            </Typography>
                            <Typography sx={{mt: 1}}>
                                {t('address')} <br/> {address}
                            </Typography>
                            <Typography sx={{mt: 1}}>
                                {t('telephone')} <br/> {phone}
                            </Typography>
                        </Stack>
                    </>
                )
            }
        </Dialog>
    )
}

export default React.memo(ClientModal)

// const basicMarkup = (photo: string) => (
//   <html lang="en">
//     <head>
//       <meta charSet="utf-8" />
//     </head>
//     <body>
//       <div>
//         <img
//           src={
//             photo
//               ? `${server_url}${photo}`
//               : no_avatar
//           }
//         />
//       </div>
//     </body>
//   </html>
// );