import React, {useState} from 'react'
import {Box, Button, Card, Grid, Stack} from "@mui/material";
import {useForm} from "react-hook-form";
import {copyObject} from "../utils/utils";
import {carModel, carSchema, ICar} from "../models";
import {Input} from "../components";
import {useTranslation} from "react-i18next";
import UploadFile from "@mui/icons-material/UploadFile";
import {useAppDispatch} from "../hooks/redux";
import {fetchCreateCar} from "../redux/actions/carsActions";
import {appSlice} from "../redux/reducers/appReducer";
import {useNavigate} from "react-router-dom";
import { useFormState } from '../hooks/use-form';
import {yupResolver} from '@hookform/resolvers/yup'

const CarAdd = () => {

    const dispatch = useAppDispatch()
    const {t} = useTranslation()
    const navigate = useNavigate()

    const [carImg, setCarImg] = useState<any>(null)

    const {control, handleSubmit, formState: {errors, submitCount}} = useForm<ICar>({
        defaultValues: copyObject(carModel),
        resolver: yupResolver(carSchema)
    })

    // const service = useFormState(errors, submitCount)

    const createCar = async (data: ICar) => {
        const formData = new FormData()
        for (let [key, value] of Object.entries(data)) {
            formData.append(key, value)
        }
        formData.append("photo", carImg)
        const res = await dispatch(fetchCreateCar(formData))
        if (res?.status === 201) {
            dispatch(appSlice.actions.toggleSystemAlert({
                severity: 'success',
                text: t('carCreateSuccess'),
                show: true,
                autoHide: true
            }))
            navigate('/app/carslist')
        }
    }

    const handleCarImg = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setCarImg(event.target.files[0])
        } else {
            setCarImg(null)
        }
    }

    return (
        <Stack sx={{maxWidth: '1400px', margin: '0 auto'}}>
            <form onSubmit={handleSubmit(createCar)}>
                <Card sx={{p: 2}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Input
                                label={t('brand')}
                                control={control}
                                errors={errors}
                                name="brand"
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Input
                                label={t('model')}
                                control={control}
                                errors={errors}
                                name="model"
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Input
                                label={t('number')}
                                control={control}
                                errors={errors}
                                name="car_num"
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Input
                                label={t('toning')}
                                control={control}
                                errors={errors}
                                name="toning"
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Input
                                label={t('yearOfIssue')}
                                type={"number"}
                                control={control}
                                errors={errors}
                                name="year_of_issue"
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Input
                                label={t('color')}
                                control={control}
                                errors={errors}
                                name="color"
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Input
                                label={t('engineNum')}
                                control={control}
                                errors={errors}
                                name="engine_num"
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Input
                                label={t('bodyNum')}
                                control={control}
                                errors={errors}
                                name="body_num"
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Input
                                label={t('techPassport')}
                                control={control}
                                errors={errors}
                                name="tech_passport"
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Input
                                label={t('mileage')}
                                control={control}
                                errors={errors}
                                name="mileage"
                                type="number"
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Input
                                label={t('totalCost')}
                                type={"number"}
                                control={control}
                                errors={errors}
                                name="cost"
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Input
                                label={t('price/day')}
                                type={"number"}
                                control={control}
                                errors={errors}
                                name="rent_cost"
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Input
                                label={t('accumulator')}
                                control={control}
                                errors={errors}
                                name="battery"
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Input
                                label={t('tire')}
                                control={control}
                                errors={errors}
                                name="wheels_model"
                                fullWidth={true}
                            />
                        </Grid>
                         <Grid item xs={12} sm={6} md={4}>
                            <Input
                                label={t('spareWheel')}
                                control={control}
                                errors={errors}
                                name="spare_wheel"
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Button variant="outlined" component="label" color="primary" aria-label="upload image">
                                <input hidden accept="image/*" type="file" onChange={handleCarImg} />
                                {t('uploadPhoto')} &nbsp; <UploadFile />
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            {
                                carImg && (
                                    <Box sx={{maxWidth: '20rem', height: '15rem', border: '1px solid black'}}>
                                        <img style={{objectFit: 'cover', width: '100%', height: '100%'}} src={URL.createObjectURL(carImg)} alt="Selected Car Image"/>
                                    </Box>
                                )
                            }
                        </Grid>
                    </Grid>
                    <Button variant={"outlined"} type={"submit"} color={"success"} sx={{mt: 2}}>
                        {t('save')}
                    </Button>
                </Card>
            </form>
        </Stack>
    )
}

export default React.memo(CarAdd)