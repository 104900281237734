import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IAlert {
  show: boolean;
  autoHide?: boolean;
  text: string;
  severity: "error" | "info" | "success" | "warning";
}

interface IInitialState {
  alert: IAlert;
  auth: boolean
}

const initialState: IInitialState = {
  auth: false,
  alert: {
    show: false,
    autoHide: false,
    text: "",
    severity: "info",
  },
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    toggleAuth(state, action: PayloadAction<boolean>) {
      state.auth = action.payload
    },
    toggleSystemAlert(state, action: PayloadAction<IAlert>) {
      if (action.payload.hasOwnProperty("autoHide")) {
        state.alert = action.payload;
      } else {
        action.payload.autoHide = true;
        state.alert = action.payload;
      }
    },
  },
});

export default appSlice.reducer