import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

// translation languages
import ru from "./ru.json";
import uz from "./uz.json";
import en from "./en.json";

const resources = {
  ru: {
    translation: ru,
  },
  uz: {
    translation: uz,
  },
  en: {
    translation: en
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["ru", "uz", "en"],
    resources,
    fallbackLng: "ru",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
