import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { BlackListModel, blacklistSchema, IBlackList } from "../models";
import { useForm } from "react-hook-form";
import {copyObject, fDashedDate} from "../utils/utils";
import {Box, Button, Card, Grid, InputAdornment, Stack} from '@mui/material'
import { Input } from "../components";
import { CustomDatePicker } from '../components/datepicker';
import CountryAutocomplete from '../components/countryAutocomplete/CountryAutocomplete';
import PersonAddAlt from '@mui/icons-material/PersonAddAlt'
import {useAppDispatch} from "../hooks/redux";
import {fetchCreateClient} from "../redux/actions/clientsActions";
import {fetchCreateBlackListClient} from "../redux/actions/blacklistActions"
import {appSlice} from "../redux/reducers/appReducer";
import {useNavigate} from "react-router-dom";
import { useFormState } from '../hooks/use-form';
import {yupResolver} from '@hookform/resolvers/yup'
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const BlackListClientAdd = () => {

    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [clientImg, setClientImg] = useState<any>(null)

    const { handleSubmit, control, setValue, formState: {errors, submitCount} } = useForm<IBlackList>({
        defaultValues: copyObject(BlackListModel),
        resolver: yupResolver(blacklistSchema)
    })

    const service = useFormState(errors, submitCount)

     const handleClientImg = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.files);
        if (event.target.files && event.target.files[0]) {
            setClientImg(event.target.files[0])
        } else {
            setClientImg(null)
        }
    }


    const createClient = async (data: IBlackList) => {
    const fd = new FormData();

    for (const [key, value] of Object.entries(data)) {
        fd.append(key, value);
    }
    fd.append('photo', clientImg);
    const res = await dispatch(fetchCreateBlackListClient(fd));
        if (res?.status === 201) {
          dispatch(
            appSlice.actions.toggleSystemAlert({
              severity: 'success',
              text: t('clientCreateSuccess'),
              show: true,
              autoHide: true,
            })
          );
          navigate('/app/black_list');
        }
    };

    return (
        <Stack sx={{ maxWidth: '1400px', margin: '0 auto' }}>
            <form onSubmit={handleSubmit(createClient)}>
                <Card sx={{ p: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Input
                                label={t('lastName')}
                                name="lastname"
                                control={control}
                                errors={errors}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Input
                                label={t('firstName')}
                                name="firstname"
                                control={control}
                                errors={errors}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Input
                                label={t('fathersName')}
                                name="second_name"
                                control={control}
                                errors={errors}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Input
                                label={t('passport')}
                                name="passport_series"
                                control={control}
                                errors={errors}
                                fullWidth={true}
                            />
                        </Grid>


                        <Grid item xs={12} sm={6} md={4}>
                            <Input
                                label={t('block_description')}
                                name="description"
                                control={control}
                                errors={errors}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Button variant="outlined" component="label" color="primary" aria-label="upload image">
                                <input hidden accept="image/*" type="file" onChange={handleClientImg} />
                                {t('uploadPhoto')} &nbsp; <PersonAddAlt />
                            </Button>
                        </Grid>
                        {
                            clientImg && (
                                <Grid item xs={12} sm={6} md={4}>
                                    <Box sx={{maxWidth: '20rem', height: '15rem', border: '1px solid black'}}>
                                        <img style={{objectFit: 'cover', width: '100%', height: '100%'}} src={URL.createObjectURL(clientImg)} alt="Selected Client Image"/>
                                    </Box>
                                </Grid>
                            )
                        }

                    </Grid>
                    <Button type="submit" sx={{mt: 2}} variant="outlined" color="success">
                        {t('Create')}
                    </Button>
                </Card>
            </form>
        </Stack>
    )
}

export default React.memo(BlackListClientAdd)