import { RootDispatch } from "./../reducers/RootReducer";
import { ICreateOrder } from "./../../models/order";
import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/orders";

const SERVER_ERROR = "Ошибка на стороне сервера";

export const fetchOrders = createAsyncThunk(
  "orders/fetchAll",
  async (payload: any, thunkAPI) => {
    try {
      const res = await api.getOrders();
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(SERVER_ERROR);
    }
  }
);

export const fetchFilterOrders = createAsyncThunk(
  "orders/fetchWithFilter",
  async (payload: any, thunkAPI) => {
    try {
      const res = await api.ordersFilter(payload);
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(SERVER_ERROR);
    }
  }
);



export const fetchCreateOrder =
  (payload: ICreateOrder) => async (dispatch: RootDispatch) => {
    try {
      const res = await api.createOrder(payload);
      // console.log(res)
      return res;
    } catch (e) {
      console.log(e);
    }
  };

export const fetchDownloadOrder = (orderId: number, lang: String) => async (dispatch: RootDispatch) => {
    try {
        return await api.getFile(orderId, lang)
    } catch (e) {
        console.log(e)
    }
}

export const fetchEditOrder = (payload: FormData, orderId: number) => async (dispatch: RootDispatch) => {
  try {
    const res = await api.editOrder(payload, orderId)
    if (res.status === 200) {
      dispatch(fetchOrders(''))
    }
    return res
  } catch (e) {
    console.log(e)
  }
}

export const fetchExtendOrder = (payload: FormData) => async (dispatch: RootDispatch) => {
  try {
    const res = await api.extendOrder(payload)
    if (res.status === 201) {
        dispatch(fetchOrders(''))
    }
    return res
  } catch (e) {
    console.log(e)
  }
}