import Alert from "./components/alert/Alert"
import { useAuth } from "./hooks/use-auth"
import Router from "./routes"
import {setLocale} from 'yup'
import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "./hooks/redux";
import {appSlice} from "./redux/reducers/appReducer";
import {fetchOrders} from "./redux/actions/ordersActions";

function App() {

  setLocale({
    mixed: {
      default: 'fillThisField'
    },
    // string: {
    //   default: 'fillThisField'
    // }
  })

  const auth = useAppSelector(state => state.appReducer.auth)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const token = JSON.parse(window.localStorage.getItem('token') || '""')
    dispatch(appSlice.actions.toggleAuth(!!token))
    if (!token) {
      dispatch(fetchOrders(''))
    }
  }, [])

  return (
    <>
      <Alert />
      <Router isAuth={auth} />
    </>
  )
}

export default App